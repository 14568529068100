import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextField from "../Typography/TextField";
import { ComparePageKeys } from "../Typography/Information";
import Button from "../Typography/Button";
import MonthYearSelect from "../Typography/MonthYearSelect";
import Tooltip from "@mui/material/Tooltip";
import Loader from "../Pre-Loader/Loader";
import {
  SideHeadings,
  MainSideHeadings,
  TextArea,
} from "../Typography/SideHeadings";
import { Chip } from "@mui/material";
import { usePdf } from "@mikecousins/react-pdf";
// import { toast } from "react-toastify";
import ExpiryScreen from "../Expiry-Login-Screen/ExpiryScreen";
// import DocxViewer from "../Typography/DocViewer";
import StaticValues from "../Compare-Popup/Details.json";
import PdfFile from "../../assets/Resume.pdf";
// import "./FileHistoryViewer.css";
import "../CandidateFileUpload/ExtractResumeData.css";
function FileHistoryViewer({
  setUploadedResumes,
  setTokenExpiry,
  setResponseData,
  tokenExpiry,
  setDateError,
  // historyfileData,
  isViewparsedata,
  setisViewparsedata,
}) {
  // const {
  //   name = "",
  //   email = "",
  //   mobile = "",
  //   // file_name = "",
  //   overall_experience = "",
  //   skills = [],
  //   experience = [],
  //   file_url: history_file_url = "",
  //   file_type: history_file_type = "pdf",
  //   // file_uuid = "",
  //   education = [],
  //   // Recruiter_Notes = "",
  // } = historyfileData || {};

  const { data = {} } = StaticValues;
  const {
    // parsed_resumes = [],
    // file_names = [],
    updated_data = [],
  } = data || {};

  const {
    name: updated_Name = "",
    email: updated_Email = "",
    mobile: updated_Mobile = "",
    overall_experience: updated_Overall_experience = "",
    skills: updated_Skills = [],
    experience: updated_Experience = [],
    education: updated_Education = [],
    // is_update = "",
    Recruiter_Notes = "",
    // file_url = "",
  } = updated_data[0] || {};
  console.log("updated_data", updated_data);
  // const [text, setText] = React.useState("");
  const [page, setPage] = React.useState(1);

  const canvasRef = React.useRef(null);
  const { pdfDocument } = usePdf({
    file: PdfFile,
    page,
    canvasRef,
  });
  // React.useEffect(() => {
  //   fetch(file_url)
  //     .then((response) => response.text())
  //     .then((data) => {
  //       setText(data);
  //     });
  // }, [history_file_url, text]);
  const handleclosePopup = () => {
    setisViewparsedata(false);
  };
  return (
    <>
      {tokenExpiry ? (
        <ExpiryScreen
          setUploadedResumes={setUploadedResumes}
          setTokenExpiry={setTokenExpiry}
          setResponseData={setResponseData}
        />
      ) : (
        <form>
          <div className="dialog-outer-container">
            <div className="dialog-container">
              <div className="resume-data">
                <div className="person-details">
                  <div className="person-data">
                    <div className="Extracted-div">
                      <h2>{ComparePageKeys.CompareHeader}</h2>
                    </div>
                    <div className="data">
                      <MainSideHeadings label={ComparePageKeys.Name} />
                      <TextField
                        name="name"
                        value={updated_Name}
                        className="compare-textfield"
                        icon={null}
                        // style={{ border: "none", fontSize: "16px" }}
                        readOnly={true}
                        thumbsup={false}
                      />{" "}
                    </div>
                    <div className="data">
                      <MainSideHeadings label={ComparePageKeys.Email} />
                      <TextField
                        type="email"
                        name="email"
                        value={updated_Email}
                        className="compare-textfield"
                        // style={{ border: "none", fontSize: "16px" }}
                        readOnly={true}
                        thumbsup={false}
                      />{" "}
                    </div>
                    <div className="data">
                      <MainSideHeadings label={ComparePageKeys.Contact} />
                      <TextField
                        name="mobile"
                        value={updated_Mobile}
                        className="compare-textfield"
                        // style={{ border: "none", fontSize: "16px" }}
                        readOnly={true}
                        thumbsup={false}
                      />
                    </div>

                    <div className="data data-skills">
                      <MainSideHeadings label={ComparePageKeys.Skills} />
                      <div
                        className={updated_Skills.length > 4 ? "skills" : ""}
                        name="skills"
                      >
                        {updated_Skills.map((skill, index) => (
                          <Chip
                            key={index}
                            label={skill}
                            variant="outlined"
                            style={{
                              marginRight: "8px",
                              marginBottom: "8px",
                              backgroundColor: "#f1f7ff",
                              color: "black",
                            }}
                          />
                        ))}
                      </div>
                    </div>

                    <div className="data data-skills">
                      <MainSideHeadings
                        label={ComparePageKeys.YearsofExperience}
                      />

                      <TextField
                        name="overall_experience"
                        value={updated_Overall_experience}
                        className="compare-textfield"
                        style={{ border: "none", fontSize: "16px" }}
                        readOnly={true}
                        thumbsup={false}
                      />
                    </div>
                    {updated_Experience.map((exp, index) => (
                      <div className="data data-skills" key={index}>
                        <MainSideHeadings label={ComparePageKeys.Experience} />

                        <div className="content">
                          <div className="green">
                            <SideHeadings
                              className="side-headings"
                              label="Occupation / Title"
                            />
                            <TextField
                              containerClassName="education-textfield"
                              className="compare-textfield"
                              name="experience.occupation"
                              value={exp.occupation || ""}
                              icon={""}
                              thumbsup={false}
                              readOnly={true}
                            />
                            <SideHeadings
                              className="side-headings"
                              label="Company"
                            />
                            <TextField
                              containerClassName="education-textfield"
                              className="compare-textfield"
                              name="experience.company"
                              value={exp.company || ""}
                              icon={""}
                              thumbsup={false}
                              readOnly={true}
                            />
                            <SideHeadings
                              className="side-headings"
                              label="Summary"
                            />
                            <TextArea
                              name="experience.summary"
                              value={exp.summary || ""}
                              icon={""}
                              className="experience-summary"
                              readOnly={true}
                            />
                            <SideHeadings
                              className="side-headings"
                              label="Work duration"
                            />
                            <MonthYearSelect
                              setDateError={setDateError}
                              readOnly={true}
                              startMonth={exp.start_month}
                              startYear={exp.start_year}
                              endMonth={exp.end_month}
                              endYear={exp.end_year}
                              errorClassName="error-message"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    {updated_Education.map((edu, index) => (
                      <div className="data data-skills" key={index}>
                        <MainSideHeadings label={ComparePageKeys.Education} />

                        <div className="content">
                          <SideHeadings
                            className="side-headings"
                            label="Institute / School"
                          />
                          <TextField
                            containerClassName="education-textfield"
                            className="compare-textfield"
                            name="education.Institute"
                            value={edu.institute || ""}
                            icon={""}
                            thumbsup={false}
                            readOnly={true}
                          />
                          <SideHeadings
                            className="side-headings"
                            label="Major / Department"
                          />
                          <TextField
                            containerClassName="education-textfield"
                            className="compare-textfield"
                            name="education.Major"
                            value={edu.department || ""}
                            icon={""}
                            thumbsup={false}
                            readOnly={true}
                          />
                          <SideHeadings
                            className="side-headings"
                            label="Degree"
                          />
                          <TextField
                            containerClassName="education-textfield"
                            className="compare-textfield"
                            name="education.Degree"
                            value={edu.degree || ""}
                            icon={""}
                            thumbsup={false}
                            readOnly={true}
                          />
                          <SideHeadings
                            className="side-headings"
                            label="Academic year"
                          />
                          <MonthYearSelect
                            readOnly={true}
                            setDateError={setDateError}
                            startMonth={edu.start_month}
                            startYear={edu.start_year}
                            endMonth={edu.end_month}
                            endYear={edu.end_year}
                            errorClassName="error-message"
                          />
                        </div>
                      </div>
                    ))}

                    <div className="recruiternotes-container">
                      <SideHeadings
                        label={ComparePageKeys.RecruiterNotes}
                        className="side-headings"
                      />

                      <TextArea
                        name="recruiterNotes"
                        className="recruiternotes"
                        value={Recruiter_Notes}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="resume-compare"
                // style={
                //   history_file_type === "docx"
                //     ? { overflow: "hidden" }
                //     : { overflow: "auto" }
                // }
              >
                <div className="Extracted-div">
                  <Tooltip title="Go Back" arrow>
                    <ArrowBackIcon onClick={handleclosePopup}></ArrowBackIcon>
                  </Tooltip>

                  <h2>Resume</h2>
                </div>

                <>
                  {isViewparsedata && (
                    <div className="pdf-container">
                      {!pdfDocument && <Loader />}
                      <canvas ref={canvasRef} />
                      {Boolean(pdfDocument && pdfDocument.numPages) && (
                        <div className="pdf-button-container">
                          <Button
                            disabled={page === 1 && true}
                            event={() => setPage(page - 1)}
                            label="Previous"
                          />
                          <p>
                            Page {page} of {pdfDocument.numPages}
                          </p>
                          <Button
                            disabled={page === pdfDocument.numPages && true}
                            event={() => setPage(page + 1)}
                            label="Next"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default FileHistoryViewer;
