import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import styled from "@emotion/styled";
export const StyledDynamicCheckbox = styled("div")({});
export default function DynamicCheckbox({
  label,
  checked,
  onChange,
  containerStyle = {},
  name = "",
  disabled,
}) {
  return (
    <StyledDynamicCheckbox
      style={containerStyle}
      className="checkbox-container"
    >
      <Checkbox
        checked={checked}
        onChange={onChange}
        inputProps={{ "aria-label": "Checkbox demo" }}
        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
        name={name}
        disabled={disabled}
      />
      {label}
    </StyledDynamicCheckbox>
  );
}
