import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import TextInput from "../Typography/TextField";
import Button from "../Typography/Button";
import { post } from "../../helper/Api_helper";
import { REGISTER_URL } from "../../helper/url_helper";
import vylabsLogo from "../Images/Vylabs Logo.png";
import Login from "../Login/Login";
import loginanimation from "../Login/newanimation.json";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import vybogLogoRP from "../Images/Vybog Resume Lookbook Logo.png";

function Signup({ setLoginFormChange = () => "", setLoading, loading }) {
  const [showPassword, setShowPassword] = useState(false);
  const [register, setregister] = useState(false);
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("Please enter your first name")
      .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed for firstName"),
    lastName: Yup.string()
      .required("Please enter your last name")
      .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed for lastName"),
    contact: Yup.string().matches(
      /^(\+\d{1,3}\s?)?\d{1,14}$/,
      "Invalid phone number"
    ),
    email: Yup.string()
      .email("Invalid email address")
      .max(35, "Email address must not exceed 35 characters")
      .required("Please enter your email address"),
    password: Yup.string()
      .required("Please enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
        "Must contain 8 characters, one uppercase, one lowercase, one number and one special character"
      )
      .max(15, "Password must not exceed 15 characters"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      contact: "",
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      setLoading(true);
      post(REGISTER_URL, {
        email: formik.values.email,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        password: formik.values.password,
        phone: formik.values.contact.toString(),
      })
        .then((response) => {
          setregister(true);
          setLoginFormChange(false);
          toast.success(response.message, { toastId: "signup" });
          formik.resetForm();
        })
        .catch((error) => {
          const { response = {} } = error || {};
          const { data = {} } = response || {};
          const { email = "" } = data || {};
          // const { username = [], email = [] } = message || {};
          // const errorMessage = [...username, ...email].join(" ");
          toast.error(email, { toastId: "signup-error" });
          console.log("signup error: ", error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
console.log("contact",formik.values.contact);
  const EyeIconToggle = () => {
    setShowPassword(!showPassword);
  };
  const handlePageChange = () => {
    if (!loading) {
      setLoading(false);
      setLoginFormChange(false);
      formik.resetForm();
    }
  };
  return (
    <>
      {register ? (
        <Login />
      ) : (
        <div className="signup-container">
          <div className="login">
            <div className="background-container">
              <div className="welcome-text">
                Welcome to Vybog <br></br>Resume Lookbook
              </div>
              <div className="login-animation">
                <Player autoplay loop src={loginanimation}>
                  <Controls
                    visible={false}
                    buttons={["play", "repeat", "frame", "debug"]}
                  />
                </Player>
              </div>
            </div>
            <form className="login-form">
              <img
                className="lookbook-logo"
                src={vybogLogoRP}
                alt="lookbook-logo"
              />
              <h1 className="login-heading">Signup</h1>
              <label className="Email-label" htmlFor="firstName">
                First name
              </label>
              <TextInput
                id="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                name="firstName"
                labelFor="firstName"
                error={formik.touched.firstName && formik.errors.firstName}
                errorMessage={formik.errors.firstName}
                containerClassName="login-input-field"
                className="form-input"
                errorClassName="error-message-login"
                autoComplete="new-password"
                thumbsup={false}
              />
              <label className="Email-label" htmlFor="lastName">
                Last name
              </label>
              <TextInput
                id="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                name="lastName"
                labelFor="lastName"
                error={formik.touched.lastName && formik.errors.lastName}
                errorMessage={formik.errors.lastName}
                containerClassName="login-input-field"
                className="form-input"
                errorClassName="error-message-login"
                autoComplete="new-password"
                thumbsup={false}
              />
              <label className="Email-label" htmlFor="contact">
                Phone number
              </label>
              <TextInput
                id="contact"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contact}
                name="contact"
                labelFor="contact"
                error={formik.touched.contact && formik.errors.contact}
                errorMessage={formik.errors.contact}
                containerClassName="login-input-field"
                className="form-input"
                errorClassName="error-message-login"
                thumbsup={false}
              />
              <label className="Email-label" htmlFor="email">
                Email address
              </label>
              <TextInput
                id="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                name="email"
                labelFor="email"
                error={formik.touched.email && formik.errors.email}
                errorMessage={formik.errors.email}
                containerClassName="login-input-field"
                className="form-input"
                errorClassName="error-message-login"
                thumbsup={false}
              />
              <label className="Password-label" htmlFor="password">
                Password
              </label>
              <TextInput
                id="password"
                type={showPassword ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                name="password"
                labelFor="password"
                error={formik.touched.password && formik.errors.password}
                errorMessage={formik.errors.password}
                passWordIcon={true}
                EyeIconToggle={EyeIconToggle}
                IconContainerClass="eye-icon"
                open={showPassword}
                containerClassName="login-input-field"
                className="form-input"
                errorClassName="error-message-login"
                autoComplete="new-password"
                thumbsup={false}
              />

              <Button
                event={formik.handleSubmit}
                type="submit"
                className={`login-button ${loading ? "loading" : ""}`}
                label={loading ? "Signup..." : "Signup"}
                loading={loading}
              />
              <div className="usertag-div">
                {" "}
                <h5 className="user-tag">
                  Already have an account?{" "}
                  <span onClick={handlePageChange}>Login</span>
                </h5>
              </div>

              <div className="copyright">
                <p className="cpyrt-text">Powered by</p>
                <a href="https://vylabs.ai/" target="blank">
                  <img className="logo2" src={vylabsLogo} alt="vybog logo" />
                </a>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default Signup;
