import React from "react";
import openEyeIcon from "../Images/eye-open.svg";
import closeEyeIcon from "../Images/eyes-close.svg";
// import ThumbsUpDown from "./ThumbsUpDown";
function TextInput(props) {
  const {
    type = "text",
    placeholder = "",
    name = "",
    value = "",
    onChange = () => "",
    // error,
    onBlur = () => "",
    className = "",
    id = "",
    label = "",
    labelFor = "",
    labelClassName = "label",
    error = "",
    errorMessage = "",
    errorClassName = "",
    required = true,
    containerClassName = "input-field",
    passWordIcon = false,
    open = false,
    EyeIconToggle = () => "",
    IconContainerClass = "",
    icon = "",
    autoComplete = "",
    readOnly = false,
    style = {},
    // thumbsup = true,
    // Thumbsup,
    // Thumbsdown,
  } = props;
  return (
    <>
      <div className={containerClassName}>
        {icon ? (
          <img
            src={icon}
            alt="mailicon"
            style={{ position: "absolute" }}
            className="mailicon"
          />
        ) : null}

        <input
          type={type || "text"}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          className={className}
          id={id}
          required={required}
          icon={icon}
          autoComplete={autoComplete}
          readOnly={readOnly}
          style={style}
        />
        {passWordIcon && (
          <span className={IconContainerClass} onClick={EyeIconToggle}>
            {open ? (
              <img src={openEyeIcon} alt="no img" />
            ) : (
              <img src={closeEyeIcon} alt="no img" />
            )}
          </span>
        )}
        <label className={labelClassName} htmlFor={labelFor}>
          {label}
        </label>
        {error && <div className={errorClassName}>{errorMessage}</div>}
        {/* {thumbsup ? (
          <ThumbsUpDown Thumbsup={Thumbsup} Thumbsdown={Thumbsdown} />
        ) : null} */}
      </div>
    </>
  );
}

export default TextInput;
