import React from "react";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

function ThumbsUpDown({ onThumbsUpClick, onThumbsDownClick, thumbsUpType }) {
  const [thumbsUpState, setThumbsUpState] = React.useState(false);
  const [thumbsDownState, setThumbsDownState] = React.useState(false);
  //   React.useEffect(() => {
  //     setThumbsUpState(Thumbsup);
  //     setThumbsDownState(Thumbsdown);
  //   }, [Thumbsdown, Thumbsup]);
  const handleThumbsUpClick = () => {
    const newThumbsUpState = !thumbsUpState;
    setThumbsUpState(newThumbsUpState);
    setThumbsDownState(false);
    onThumbsUpClick && onThumbsUpClick(newThumbsUpState, thumbsUpType, true);
  };

  const handleThumbsDownClick = () => {
    const newThumbsDownState = !thumbsDownState;
    setThumbsDownState(newThumbsDownState);
    setThumbsUpState(false);
    onThumbsDownClick &&
      onThumbsDownClick(newThumbsDownState, thumbsUpType, false);
  };

  return (
    <div className="thumbsup-content">
      {thumbsUpState ? (
        <ThumbUpIcon onClick={handleThumbsUpClick} />
      ) : (
        <ThumbUpOffAltIcon onClick={handleThumbsUpClick} />
      )}
      {thumbsDownState ? (
        <ThumbDownIcon onClick={handleThumbsDownClick} />
      ) : (
        <ThumbDownOffAltIcon onClick={handleThumbsDownClick} />
      )}
    </div>
  );
}

export default ThumbsUpDown;
