import React from "react";
import "../Footer/Footer.css";
import vybogLogo from "../Images/Vybog Resume Lookbook Logo.png";
import vylabsLogo from "../Images/Vylabs Logo.png";
function Footer({ loading }) {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div
        className="footer"
        style={loading ? { opacity: "0.4" } : { opacity: "unset" }}
      >
        <div className="logo">
          <a href="/">
            <img src={vybogLogo} alt="Logo" />
          </a>
        </div>
        <div className="vylabs-content">
          <p>Powered by</p>
          <a href="https://vylabs.ai/" target="blank">
            {" "}
            <img src={vylabsLogo} alt="Vylabs Logo" className="vylabsLogo" />
          </a>
        </div>
        <div className="copy-rigth">
          <p className="copyright-text">
            {" "}
            {`Copyright ©${currentYear} Vybog Inc. All rights reserved.`}
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
