import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextInput from "../Typography/TextField";
import Button from "../Typography/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-toastify";
import GlobalEnterKeyHandler from "../Typography/GlobalEnterKeyHandler";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import loginanimation from "../Login/newanimation.json";
import Vylabslogo from "../Images/Vylabs Logo.png";
import vybogLogoRP from "../Images/Vybog Resume Lookbook Logo.png";

function ResetPassword({ setResetPasswordPage, setForgotPassword, props }) {
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  GlobalEnterKeyHandler();

  const handleBack = () => {
    setForgotPassword(false);
    setResetPasswordPage(false);
  };

  const EyeIconToggle = (field) => {
    switch (field) {
      case "newPassword":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirmPassword":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };
  const formik = useFormik({
    initialValues: {
      code: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .required("Code is required")
        .matches(
          /^[0-9]{4}$/,
          "Code must contain exactly 4 numbers and no alphabets"
        ),
      newPassword: Yup.string()
        .required("New Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
          "Must contain 8 characters, one uppercase, one lowercase, one number and one special character"
        ),
      confirmPassword: Yup.string()
        .oneOf(
          [Yup.ref("newPassword"), null],
          "Passwords must match with New Password"
        )
        .required("Confirm Password is required"),
    }),
    onSubmit: (values) => {
      console.log(values);
      toast.success("Password updated successfully", { toastId: "success" });
      setForgotPassword(false);
      setResetPasswordPage(false);
    },
  });

  return (
    <>
      <div className="resetpassword">
        <div className="login">
          <div className="background-container">
            <div className="welcome-text">
              {" "}
              Welcome to Vybog <br></br>Resume Lookbook
            </div>
            <div className="login-animation">
              <Player autoplay loop src={loginanimation}>
                <Controls
                  visible={false}
                  buttons={["play", "repeat", "frame", "debug"]}
                />
              </Player>
            </div>
          </div>
          <form className="login-form">
            <img
              className="lookbook-logo"
              alt="lookbook-logo"
              src={vybogLogoRP}
            />
            <div className="password-back-button-div">
              <ArrowBackIcon onClick={handleBack} />
              <Button
                label="Login"
                event={handleBack}
                className="password-back-button"
              />
            </div>
            <h1 className="login-heading">Reset Password </h1>
            <label className="oldpassword" htmlFor="code">
              Code
            </label>
            <TextInput
              type={"text"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.code}
              name="code"
              error={formik.touched.code && formik.errors.code}
              errorMessage={formik.errors.code}
              containerClassName="login-input-field"
              className="form-input"
              errorClassName="error-message-login"
              thumbsup={false}
              autoComplete="new-password"
            />
            <label className="newpassword" htmlFor="NewPassword">
              NewPassword
            </label>

            <TextInput
              type={showNewPassword ? "text" : "password"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.newPassword}
              name="newPassword"
              error={formik.touched.newPassword && formik.errors.newPassword}
              errorMessage={formik.errors.newPassword}
              passWordIcon={true}
              EyeIconToggle={() => EyeIconToggle("newPassword")}
              open={showNewPassword}
              IconContainerClass="eye-icon"
              containerClassName="login-input-field"
              className="form-input"
              errorClassName="error-message-login"
              thumbsup={false}
              autoComplete="new-password"
            />
            <label htmlFor="confirmPassword" className="confirmpassword">
              ConfirmPassword
            </label>
            <TextInput
              type={showConfirmPassword ? "text" : "password"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              name="confirmPassword"
              error={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              errorMessage={formik.errors.confirmPassword}
              passWordIcon={true}
              EyeIconToggle={() => EyeIconToggle("confirmPassword")}
              open={showConfirmPassword}
              IconContainerClass="eye-icon"
              containerClassName="login-input-field"
              className="form-input"
              errorClassName="error-message-login"
              thumbsup={false}
              autoComplete="new-password"
            />

            <div className="forgotPasswordButtons">
              <div className="back-button-forgot">
                <Button
                  type="submit"
                  label="Back"
                  className="login-button"
                  event={handleBack}
                />
              </div>
              <div className="reset-button">
                <Button
                  type="submit"
                  label="Reset"
                  className="login-button"
                  event={formik.handleSubmit}
                />
              </div>
            </div>
            <div className="copyright">
              <p className="cpyrt-text">Powered by</p>
              <a href="https://vylabs.ai/" target="blank">
                <img className="logo2" src={Vylabslogo} alt="vybog logo" />
              </a>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
