import React, { useState, useEffect } from "react";
// import ThumbsUpDown from "./ThumbsUpDown";
function MonthYearSelect({
  label,
  startMonth,
  startYear,
  endMonth,
  endYear,
  onUpdateStartMonth,
  onUpdateStartYear,
  onUpdateEndMonth,
  onUpdateEndYear,
  error,
  errorClassName,
  readOnly,
  setDateError = () => "",
  // thumbsup = true,
  containerClassName = "",
  // Thumbsup,
  // Thumbsdown,
}) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 50 }, (_, i) => currentYear - i);
  const [localStartMonth, setLocalStartMonth] = useState(startMonth || "");
  const [localStartYear, setLocalStartYear] = useState(startYear || "");
  const [localEndMonth, setLocalEndMonth] = useState(endMonth || "");
  const [localEndYear, setLocalEndYear] = useState(endYear || "");
  const [endYears, setEndYears] = useState([]);

  useEffect(() => {
    setLocalStartMonth(startMonth || "");
    setLocalStartYear(startYear || "");
    setLocalEndMonth(endMonth || "");
    setLocalEndYear(endYear || "");

    const startYearInt = parseInt(startYear, 10);
    const startMonthIndex = months.indexOf(startMonth);
    const availableEndYears = years.filter(
      (year) =>
        year > startYearInt ||
        (year === startYearInt &&
          months.indexOf(localEndMonth) >= startMonthIndex)
    );
    setEndYears(availableEndYears);
    const isDateError =
      parseInt(localEndYear, 10) < parseInt(localStartYear, 10) ||
      (parseInt(localEndYear, 10) === parseInt(localStartYear, 10) &&
        months.indexOf(localEndMonth) < months.indexOf(localStartMonth)) ||
      (parseInt(localEndYear, 10) === parseInt(localStartYear, 10) &&
        localEndMonth === localStartMonth);

    setDateError(isDateError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startMonth, startYear, endMonth, endYear]);

  const handleStartMonthChange = (event) => {
    if (!readOnly) {
      setLocalStartMonth(event.target.value);
      onUpdateStartMonth && onUpdateStartMonth(event.target.value);
    }
  };

  const handleStartYearChange = (event) => {
    if (!readOnly) {
      setLocalStartYear(event.target.value);
      onUpdateStartYear && onUpdateStartYear(event.target.value);
      const startYearInt = parseInt(event.target.value, 10);
      const startMonthIndex = months.indexOf(localStartMonth);
      const availableEndYears = years.filter(
        (year) =>
          year > startYearInt ||
          (year === startYearInt &&
            months.indexOf(localEndMonth) >= startMonthIndex)
      );
      setEndYears(availableEndYears);
      if (!availableEndYears.includes(parseInt(localEndYear, 10))) {
        setLocalEndYear("");
        onUpdateEndYear && onUpdateEndYear("");
      }
    }
  };

  const handleEndMonthChange = (event) => {
    if (!readOnly) {
      setLocalEndMonth(event.target.value);
      onUpdateEndMonth && onUpdateEndMonth(event.target.value);

      if (
        localEndYear &&
        months.indexOf(localEndMonth) < months.indexOf(localStartMonth) &&
        parseInt(localEndYear, 10) <= parseInt(localStartYear, 10)
      ) {
        setLocalEndYear("");
        onUpdateEndYear && onUpdateEndYear("");
      }
    }
  };

  const handleEndYearChange = (event) => {
    if (!readOnly) {
      setLocalEndYear(event.target.value);
      onUpdateEndYear && onUpdateEndYear(event.target.value);
    }
  };

  return (
    <>
      <div className={containerClassName}>
        <div className="month-year-select">
          {/* <div className="select-label">{label}</div> */}
          <div className="select-container">
            <select
              value={localStartMonth}
              onChange={handleStartMonthChange}
              disabled={readOnly}
              className="month-container"
              style={
                localStartMonth || readOnly
                  ? { width: "48px", marginLeft: "10px", cursor: "no-drop" }
                  : { width: "60px", marginLeft: "10px" }
              }
            >
              <option value="" disabled hidden>
                Month
              </option>
              {months.map((month, index) => (
                <option key={index} value={month}>
                  {month}
                </option>
              ))}
            </select>
          </div>

          <div className="select-container">
            <select
              value={localStartYear}
              onChange={handleStartYearChange}
              disabled={readOnly}
              className="year-container"
              style={readOnly ? { cursor: "no-drop" } : null}
            >
              <option value="" disabled hidden>
                Year
              </option>
              {years.map((year, index) => (
                <option key={index} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
          <div className="to-label">To</div>
          <div className="select-container">
            <select
              value={localEndMonth}
              onChange={handleEndMonthChange}
              disabled={readOnly}
              className="month-container"
              style={
                localEndMonth || readOnly
                  ? { width: "48px", cursor: "no-drop" }
                  : { width: "60px" }
              }
            >
              <option value="" disabled hidden>
                Month
              </option>
              {months.map((month, index) => (
                <option key={index} value={month}>
                  {month}
                </option>
              ))}
            </select>
          </div>
          <div className="select-container">
            <select
              value={localEndYear}
              onChange={handleEndYearChange}
              disabled={readOnly}
              className="year-container"
              style={
                localEndYear || readOnly
                  ? { marginRight: "10px", cursor: "no-drop" }
                  : { marginRight: "10px" }
              }
            >
              <option value="" disabled hidden>
                Year
              </option>
              {endYears.map((year, index) => (
                <option key={index} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>
        {error && <div className={errorClassName}>{error}</div>}
        {localStartYear && localEndYear && localEndMonth && (
          <div className={errorClassName}>
            {parseInt(localEndYear, 10) < parseInt(localStartYear, 10) ||
            (parseInt(localEndYear, 10) === parseInt(localStartYear, 10) &&
              months.indexOf(localEndMonth) <
                months.indexOf(localStartMonth)) ||
            (parseInt(localEndYear, 10) === parseInt(localStartYear, 10) &&
              localEndMonth === localStartMonth)
              ? "End date must be after start date"
              : ""}
          </div>
        )}
        {/* {thumbsup ? (
          <ThumbsUpDown Thumbsup={Thumbsup} Thumbsdown={Thumbsdown} />
        ) : null} */}
      </div>
    </>
  );
}

export default MonthYearSelect;
