import React from "react";
import ExpiryLogo from "../Images/logo-error.svg";
import "../Expiry-Login-Screen/Expiry-login.css";
import { useNavigate } from "react-router-dom";
function ExpiryScreen({ setTokenExpiry, setUploadedResumes }) {
  const navigate = useNavigate();
  const clearsession = () => {
    setTokenExpiry(false);
    setUploadedResumes([]);
    localStorage.clear("email");
    navigate("/");
  };
  return (
      
    <div className="expiry-login-container-outer">
      <div className="expiry-login-container-popup">
      <div className="expiry-login-container">
        <img src={ExpiryLogo} alt="no img" />
        <h1>"Your session is expired. Please login again"</h1>
        <button className="re-login" onClick={() => clearsession()}>
          {" "}
          Login Again
        </button>
      </div>
      </div>
      </div>
  );
}

export default ExpiryScreen;
