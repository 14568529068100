import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../Typography/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CancelIcon from "@mui/icons-material/Cancel";
import "./UploadHistory.css";
import FileHistoryViewer from "./FileHistoryViewer";
import { USER_FILE_HISTORY_URL } from "../../helper/url_helper";
import { get } from "../../helper/Api_helper";
import Paginations from "../Typography/Pagination";
import Footer from "../Footer/Footer";
import { toast } from "react-toastify";
import ExpiryScreen from "../Expiry-Login-Screen/ExpiryScreen";
import { DELETE_URL } from "../../helper/url_helper";
import { deleteRequest } from "../../helper/Api_helper";
import nodata from "../Images/no-data-found.jpeg";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import deleteAnimation from "./Delete-Animation.json";
import Loader from "../Pre-Loader/Loader";
function UploadHistory(props) {
  const [fileresponseData, setFileresponseData] = useState([]);
  const navigate = useNavigate();
  const {
    // setUploadedResumes,
    // setTokenExpiry,
    // setResponseData,
    // Logout,
    // setLogout,
    isViewparsedata,
    setisViewparsedata,
    // setUserDetailsID,
    setTokenExpiry,
    tokenExpiry,
    setUploadedResumes,
    setResponseData,
    isRefetched,
    setisRefetched,
    deleteDialog,
    setDeleteDialog,
    loading,
    setLoading,
  } = props || {};
  const [deleteLoading, setdeleteLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalFiles, setTotalFiles] = useState(null);
  const [deleteFileId, setDeleteFileId] = useState(null);
  const itemsPerPage = 10;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleBack = () => {
    setUploadedResumes([]);
    navigate("/file-upload");
  };
  const handleOpenPopup = (fileId) => {
    setDeleteFileId(fileId);
    setDeleteDialog(true);
  };
  const handleDeleteFile = async () => {
    setdeleteLoading(true);
    try {
      const response = await deleteRequest(DELETE_URL + `${deleteFileId}`);
      setisRefetched(true);
      const { message = "" } = response;
      toast.success(message, { toastId: "success" });
      setFileresponseData((prevData) =>
        prevData.filter((file) => file.id !== deleteFileId)
      );
      const remainingFiles = totalFiles - 1;
      const remainingPages = Math.ceil(remainingFiles / itemsPerPage);
      if (currentPage > remainingPages) {
        setCurrentPage(remainingPages || 1);
      }
    } catch (error) {
      console.error("File upload error:", error);
      if (error.response) {
        const { data } = error.response;
        const { detail = "", code = "", messages = [] } = data || {};
        if (messages && messages.length > 0) {
          const errorMessage = messages.map((msg) => msg.message).join(", ");
          toast.error(errorMessage, { toastId: "error" });
        }
        if (code === "token_not_valid") {
          setTokenExpiry(true);
        } else {
          toast.error(detail || code || "An error occurred", {
            toastId: "error",
          });
        }
      } else if (error.request) {
        toast.error("No response received from the server", {
          toastId: "error",
        });
      } else {
        toast.error("An error occurred", { toastId: "error" });
      }
    } finally {
      setdeleteLoading(false);
      setDeleteDialog(false);
      setLoading(false);
    }
  };
  const handleClosePopup = () => {
    setDeleteDialog(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await get(
          USER_FILE_HISTORY_URL +
            `?limit=${itemsPerPage}&offset=${(currentPage - 1) * itemsPerPage}`
        );
        setisRefetched(false);
        const { total_files = "", data = [] } = response || {};
        setFileresponseData(data);
        setTotalFiles(total_files);
        setTotalPages(Math.ceil(total_files / itemsPerPage));
        console.log("file history response", total_files);
      } catch (error) {
        console.error("File upload error:", error);
        if (error.response) {
          const { data } = error.response;
          const { code = "", messages = [] } = data || {};
          if (messages && messages.length > 0) {
            const errorMessage = messages.map((msg) => msg.message).join(", ");
            toast.error(errorMessage, { toastId: "error" });
          }
          if (code === "token_not_valid") {
            setTokenExpiry(true);
          }
        } else if (error.request) {
          toast.error("No response received from the server", {
            toastId: "error",
          });
        } else {
          toast.error("An error occurred", { toastId: "error" });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    if (isRefetched) {
      fetchData();
    }
  }, [
    currentPage,
    deleteLoading,
    isRefetched,
    setLoading,
    setTokenExpiry,
    setisRefetched,
  ]);

  const formatDateString = (dateString, includeTime = true) => {
    const optionsToday = {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    };

    const optionsOtherDay = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const date = new Date(dateString);
    const today = new Date();

    if (date.toDateString() === today.toDateString()) {
      const todayString = date.toLocaleDateString("en-US", optionsToday);
      const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "long" });
      return {
        date: `Today - ${todayString.substring(
          todayString.indexOf(",") + 2
        )} - ${dayOfWeek}`,
        time: includeTime
          ? date.toLocaleTimeString("en-US", {
              hour: "numeric",
              minute: "numeric",
            })
          : "",
      };
    } else {
      const formattedDate = date.toLocaleDateString("en-US", optionsOtherDay);
      const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "long" });
      return {
        date: `${formattedDate} - ${dayOfWeek}`,
        time: includeTime
          ? date.toLocaleTimeString("en-US", {
              hour: "numeric",
              minute: "numeric",
            })
          : "",
      };
    }
  };

  const groupFilesByDate = (files) => {
    const groupedFiles = {};
    files.forEach((file) => {
      const dateKey = new Date(file.updated_at).toLocaleDateString("en-US");
      if (!groupedFiles[dateKey]) {
        groupedFiles[dateKey] = [];
      }
      groupedFiles[dateKey].push(file);
    });
    return groupedFiles;
  };

  const groupedFiles = groupFilesByDate(fileresponseData);
  return (
    <>
      {tokenExpiry ? (
        <ExpiryScreen
          setUploadedResumes={setUploadedResumes}
          setTokenExpiry={setTokenExpiry}
          setResponseData={setResponseData}
        />
      ) : (
        <div className="upload-history-container">
          {isViewparsedata ? (
            <FileHistoryViewer
              isViewparsedata={isViewparsedata}
              setisViewparsedata={setisViewparsedata}
            />
          ) : (
            <div>
              <div className="back-button-div">
                <ArrowBackIcon onClick={handleBack} />
                <Button
                  label="Back to home"
                  event={() => handleBack()}
                  className="back-button"
                />
              </div>
              <div
                className="upload-main-container"
                style={
                  fileresponseData.length === 0
                    ? { height: "100vh" }
                    : { height: null }
                }
              >
                <div className="heading-content">
                  <h2 className="heading">Upload History</h2>
                </div>

                {loading && !deleteLoading && !isRefetched ? (
                  <Loader />
                ) : (
                  <>
                    {Array.isArray(fileresponseData) &&
                    fileresponseData.length === 0 ? (
                      <div className="nodata">
                        <img
                          src={nodata}
                          alt="no-data"
                          className="no-data-img"
                        />
                      </div>
                    ) : (
                      <>
                        {Object.entries(groupedFiles).map(
                          ([date, files], index) => (
                            <div key={index} className="date-container">
                              <div className="date-heading">
                                <p className="date">
                                  {formatDateString(date, false).date}
                                </p>
                              </div>

                              {files.map((file, fileIndex) => (
                                <div
                                  key={fileIndex}
                                  className="content-div"
                                  style={{ border: "2px solid #e6e1e1" }}
                                >
                                  <div className="content-box">
                                    <div className="file-container">
                                      {" "}
                                      <p className="update-time">
                                        {formatDateString(file.updated_at).time}
                                      </p>
                                      <div className="content-box-left">
                                        <div className="content-box-uploadfileicon">
                                          <UploadFileIcon />
                                          <p>{file.name}</p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="content-box-right">
                                      <Button
                                        label="View Parsed Data"
                                        className="parse-button"
                                        event={() => setisViewparsedata(true)}
                                      />

                                      <Button
                                        className="delete-button "
                                        label="Delete"
                                        event={() => handleOpenPopup(file.id)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
              {fileresponseData.length > 0 ? (
                <div className="pagination-container">
                  <Paginations
                    onPageChange={handlePageChange}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalFiles}
                  />
                </div>
              ) : null}
            </div>
          )}
          {deleteDialog && (
            <div className="delete-popup-container">
              <div className="delete-popup-outer">
                <div className="delete-popup-heading">
                  <CancelIcon
                    className="close-icon"
                    onClick={handleClosePopup}
                  ></CancelIcon>
                </div>
                <div className="delete-popup">
                  <div className="delete-popup-div">
                    <div className="close-div">
                      <Player
                        autoplay
                        loop
                        src={deleteAnimation}
                        style={{ height: "200px", width: "350px" }}
                        className="logout-animation"
                      >
                        <Controls
                          visible={false}
                          buttons={["play", "repeat", "frame", "debug"]}
                        />
                      </Player>
                    </div>
                    <h1>Are you sure?</h1>
                    <p>Do you want to delete this file upload history</p>
                    <Button
                      event={handleClosePopup}
                      className="disagree"
                      label="Cancel"
                    />
                    <Button
                      event={handleDeleteFile}
                      className={`agree ${deleteLoading ? "loading" : ""}`}
                      label={deleteLoading ? "Deleting..." : "Delete"}
                      customLoading={deleteLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <Footer />
        </div>
      )}
    </>
  );
}

export default UploadHistory;
