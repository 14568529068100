import axios from "axios";
import config from "./config";

export const axiosApi = axios.create({
  baseURL: config[process.env.REACT_APP_ENV].baseURL,
});

axiosApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export async function get(url, config) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, config) {
  return await axiosApi
    .post(url, { ...config })
    .then((response) => response.data);
}


export async function deleteRequest(url, config) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config) {
  return await axiosApi
    .put(url, data, { ...config })
    .then((response) => response.data);
}
export async function postFormData(url, formData) {
  return await axiosApi
    .post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
}
