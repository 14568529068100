import React, { useRef, useState, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import UploadImg from "../Images/upload-icon.svg";
import "../CandidateFileUpload/candidatefile.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../Typography/Button";
import { post, put } from "../../helper/Api_helper";
import Loader from "../Pre-Loader/Loader";
import ExpiryScreen from "../Expiry-Login-Screen/ExpiryScreen";
import Tooltip from "@mui/material/Tooltip";
import { BASE_URL, PARSED_RESUME_URL, PROCESS_UPLOAD_FILE } from "../../helper/url_helper";

function AddResume(props) {
  const navigate = useNavigate();
  const {
    UploadedResumes,
    setUploadedResumes,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    handleFileChange,
    isDragOver,
    handleResetForm,
    FileRef,
    // fileToBase64,
    loading,
    setLoading,
    // setBase64,
    tokenExpiry,
    setTokenExpiry = () => "",
    // uploadedFileNames,
    setUploadedFileNames,
    // uploadFileID,
    setUploadFileID = () => "",
  } = props || {};

  const wsocket = useRef(null);
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);

  const handlesubmit = async () => {
    setLoading(true);
    const files = UploadedResumes;
    const fileNames = UploadedResumes.map((file) => file.name);
    console.error('files:', files);
    const presignedUrls = await getPresignedUploadUrls(fileNames);
    console.error('presignedUrls:', presignedUrls);


    const uploadPromises = files.map((file, index) => {
      console.error('uploadPromises files:', file.name);
      const presignedUrl = presignedUrls[index];
      if (presignedUrl) {
          return fetch(presignedUrl, {
              method: 'PUT',
              body: file,
              headers: {
                  'Content-Type': 'application/octet-stream',
              },
              mode: 'cors',
          })
              .then(response => {
                  if (response.ok) {
                    console.error('presignedUrls response:', file.name);
                      processUploadedFile(file.name);
                  } else {
                      alert(`File ${file.name} upload failed.`);
                  }
              })
              .catch(error => {
                  console.error('Upload error:', error);
              });
      }
  });

  // Wait for all the uploads to complete
  await Promise.all(uploadPromises);

    /*try {
      // const formData = new FormData();
      // UploadedResumes.forEach((file) => {
      //   formData.append("file", file);
      // });
      const names = UploadedResumes.map((file) => file.name);
      console.log("names:::", names);
      const response = await post(PARSED_RESUME_URL, {
        fileNames: names,
        // headers: {
        //   "Content-Type": "multipart/form-data",
        // },
      });
      console.log("file upload response", response);

      fetchDataForItems(names);
     
      //response.map(async (item) => {
        //console.log("responseParsedData item:", item);
        //const responseParsedData = await post(PARSED_RESUME_URL, {
          //fileName: item,
        //});
        //console.log("responseParsedData:", responseParsedData);
      //})
    

      const { message = "", data = [], task_id = "" } = response || {};

      // const names = data.map(({ name }) => name);

      const file_id = data.map(({ id }) => id);
      setUploadFileID(file_id);
      setUploadedFileNames(names);
      toast.success(message, { toastId: "success" });
      // const names = UploadedResumes.map((file) => file.name);
      setUploadedFileNames(names);
      //navigate("/extract-resume-page");

      // console.log("file upload task_id ", task_id);
    } catch (error) {
      console.error("File upload error:", error);
      if (error.response) {
        const { data } = error.response;
        const { detail = "", code = "", messages = [] } = data || {};
        if (messages && messages.length > 0) {
          const errorMessage = messages.map((msg) => msg.message).join(", ");
          toast.error(errorMessage, { toastId: "error" });
        }
        if (code === "token_not_valid") {
          setTokenExpiry(true);
        } else {
          toast.error(detail || code || "An error occurred", {
            toastId: "error",
          });
        }
      } else if (error.request) {
        toast.error("No response received from the server", {
          toastId: "error",
        });
      } else {
        toast.error("An error occurred", { toastId: "error" });
      }
    } finally {
      setLoading(false);
    }*/
  };

  const getPresignedUploadUrls = async (files) => {
    const token = localStorage.getItem("token");
    const requestBody = JSON.stringify({ FileNames: files });
    try {
      const response = await fetch(BASE_URL+PARSED_RESUME_URL, {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json',
          },
          body: requestBody,
      });

      const data = await response.json();
      console.log('getPresignedUploadUrls presigned urls:', data.PresignedUploadURLs)
      return data.PresignedUploadURLs;
    } catch (error) {
      console.error('Error:', error);
      return [];
    }
  }

  const processUploadedFile = async (fileName) => {
    //var port = window.location.hostname === 'localhost' ? ':90' : '';
    let requestUrl = BASE_URL+PROCESS_UPLOAD_FILE
    const token = localStorage.getItem("token");

    const requestBody = JSON.stringify({ fileName: fileName });
    console.error('processUploadedFile requestBody:', requestBody);

    fetch(requestUrl, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token, // Include the authorization header
            'Content-Type': 'application/json',
        },
        body: requestBody,
    })
        .then(response => {
            if (response.ok) {
                response.text().then(text => {
                    //appendMessage(`File processing initiated successfully: ${fileName}`)
                    console.error(`File processing initiated successfully: ${fileName}`);
                    setMessages("File processing initiated successfully");
                });
            } else {
                console.log('File processing failed. Check console for response.');
                response.text().then(text => {
                    console.error('Response:', text);
                    alert('Failed to initiate file processing.');
                });
            }
        })
        .catch(error => {
            console.error('Error processing file:', error);
            alert('Error in processing file. See console for details.');
        });
}


  useEffect(() => {

    // Create WebSocket connection.
    const token = localStorage.getItem("token");
    //var wsProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
    var wsProtocol = 'wss://';
    // use port 90 for localhost; otherwise, use an empty string to default to the standard port
    //var port = window.location.hostname === 'localhost' ? ':90' : '';
    //var wsUrl = wsProtocol + window.location.hostname + port + '/ws?token=' + encodeURIComponent('Bearer ' + token);
    var wsUrl = wsProtocol + 'api-stage.vylabsapi.com' + '/ws?token=' + encodeURIComponent('Bearer ' + token);
  
    const ws = new WebSocket(wsUrl);

    // Set the WebSocket instance to the state
    setSocket(ws);


    //wsocket.current = new WebSocket(wsUrl);

    // Connection opened
    ws.onopen = () => {
      console.log('WebSocket connection established');
    };

    // Listen for messages
    // ws.onmessage = (event) => {
    //   const data = JSON.parse(event.data);
    //   console.log('Message received:', data);
    //   setMessages((prevMessages) => [...prevMessages, data]);
    // };

    ws.onmessage = function(event) {
      const messageParts = event.data.split('|');

      if (messageParts.length === 2) {
          // handle message with structured format
          const topic = messageParts[0];
          let data = messageParts[1];

          // remove leading and trailing double quotes
          data = data.replace(/^"|"$/g, '');

          try {
              let jsonData = JSON.parse(data);

              // check if the jsonData has a parsed_resume field and parse it if it's a string
              if (jsonData.parsed_resume && typeof jsonData.parsed_resume === 'string') {
                  jsonData.parsed_resume = JSON.parse(jsonData.parsed_resume);
              }

              const prettyJson = JSON.stringify(jsonData, null, 2); // Format JSON with 2 spaces indentation
              console.log('[Received WebSocket Message] \n' + topic + '\n' + prettyJson);
              toast.success('[Received WebSocket Message] \n' + topic + '\n' + prettyJson, { toastId: "success" });
          } catch (error) {
              console.log('[Received WebSocket Message] \n' + event.data);
          }
      } else {
          // for testing with ws.html: handle simple messages that do not have a structured format
          console.log('[Received WebSocket Message] \n' + event.data);
      }
    };

    // Connection opened
    /*ws.onopen = () => {
      console.log('WebSocket connection established');
    };

    // Listen for messages
    ws.onmessage = (event) => {
      console.log('Message received:', event.data);
      //setMessages((prevMessages) => [...prevMessages, event.data]);
    };*/

    // Connection closed
    ws.onclose = () => {
      console.log('WebSocket connection closed');
    };

    // Handle errors
    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    // Cleanup on component unmount
    return () => {
      //ws.close();
    };
  }, [messages]);


  const handleCancel = (file) => {
    const resumelist = [...UploadedResumes];
    resumelist?.splice(file, 1);
    setUploadedResumes(resumelist);
  };
  return (
    <>
      {tokenExpiry ? (
        <ExpiryScreen
          setTokenExpiry={setTokenExpiry}
          setUploadedResumes={setUploadedResumes}
        />
      ) : (
        <>
          <div
            className="add-resume-outer"
            style={loading ? { opacity: "0.4" } : { opacity: "unset" }}
          >
            <h3>Upload Resumes</h3>
            <div className="full-screen-div">
              <div className="listed-files-container">
                {UploadedResumes.map((file, index) => (
                  <div key={index} className="list-files-inner">
                    <div className="list-files-icon">
                      <UploadFileIcon />
                      <p className="resume-filename">{file.name}</p>
                    </div>
                    <Tooltip title="Cancel" arrow>
                      <ClearIcon onClick={() => handleCancel(index)}>
                        Cancel
                      </ClearIcon>
                    </Tooltip>
                  </div>
                ))}
              </div>

              <div
                className={`file-drop-container ${
                  isDragOver ? "drag-over" : ""
                }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={() => {
                  FileRef.current.click();
                }}
              >
                <img src={UploadImg} alt="upload icon"></img>
                <label
                  htmlFor="resume-upload"
                  className="file-upload-label"
                  onClick={(e) => e.stopPropagation()}
                >
                  <p className="drag-drop">
                    Drag & drop or{" "}
                    <span className="choose-file">choose file</span> all resumes
                    here
                  </p>
                  <input
                    hidden={true}
                    ref={FileRef}
                    id="resume-upload"
                    type="file"
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileChange}
                    multiple
                  />
                </label>
                <p className="supported-file">
                  Supported Formats: PDF, Word(Doc),DocX,txt,rtf
                </p>
              </div>
            </div>

            <div className="upload-section-btn">
              <Button
                event={handleResetForm}
                className="cancel"
                label="Cancel"
              />
              <Button
                event={handlesubmit}
                type="submit"
                className="import"
                label="Import"
              />
            </div>
          </div>
          {loading && <Loader />}
        </>
      )}
    </>
  );
}

export default AddResume;
