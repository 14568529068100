import React from "react";
// import ThumbsUpDown from "./ThumbsUpDown";
const SideHeadings = (props) => {
  const { className = "", label = "" } = props || {};
  return <h4 className={className}>{label}</h4>;
};
const MainSideHeadings = (props) => {
  const { className = "", label = "" } = props || {};
  return <h6 className={className}>{label}</h6>;
};
const TextArea = (props) => {
  const {
    name = "",
    className = "",
    value = "",
    onBlur = () => "",
    onChange = () => "",
    error = "",
    errorMessage = "",
    errorClassName = "",
    readOnly = false,
    // thumbsup = true,
    // Thumbsup,
    // Thumbsdown,
    containerClassName = "",
  } = props || {};

  return (
    <>
      <div className={containerClassName}>
        <textarea
          rows="5"
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          className={className}
          readOnly={readOnly}
        />
        {error && <div className={errorClassName}>{errorMessage}</div>}
        {/* {thumbsup ? (
          <ThumbsUpDown Thumbsup={Thumbsup} Thumbsdown={Thumbsdown} />
        ) : null} */}
      </div>
    </>
  );
};

const EditedcheckBox = (props) => {
  const { className = "", value = "", onChange = () => "" } = props || {};
  return (
    <input
      type="checkbox"
      className={className}
      value={value}
      onChange={onChange}
    />
  );
};

export { SideHeadings, MainSideHeadings, TextArea, EditedcheckBox };
