import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import TextInput from "../Typography/TextField";
import Footer from "../Footer/Footer";
import Button from "../Typography/Button";
import "./UpdatePassword.css";
import sideImg from "../Images/updatepage-sideimg.jpg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { post } from "../../helper/Api_helper";
import { UPDATE_PASSWORD_URL } from "../../helper/url_helper";
import { toast } from "react-toastify";
import ExpiryScreen from "../Expiry-Login-Screen/ExpiryScreen";
import GlobalEnterKeyHandler from "../Typography/GlobalEnterKeyHandler";
function UpdatePassword(props) {
  const {
    setUploadedResumes,
    setTokenExpiry,
    setResponseData,
    tokenExpiry,
    setLoading,
    loading,
  } = props || {};
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const navigate = useNavigate();
  GlobalEnterKeyHandler();
  const handleBack = () => {
    if (!loading) {
      setUploadedResumes([]);
      navigate("/file-upload");
      formik.resetForm();
    }
  };

  const EyeIconToggle = (field) => {
    switch (field) {
      case "oldPassword":
        setShowOldPassword(!showOldPassword);
        break;
      case "newPassword":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirmPassword":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };
  const clearsession = () => {
    localStorage.clear("email");
    navigate("/");
    setUploadedResumes([]);
    setTokenExpiry(false);
    setResponseData([]);
  };
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Old Password is required"),
      newPassword: Yup.string()
        .required("New Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
          "Must contain 8 characters, one uppercase, one lowercase, one number and one special character"
        )
        .test(
          "notSame",
          "Old password and new password cannot be the same",
          function (value) {
            return value !== this.parent.oldPassword;
          }
        ),
      confirmPassword: Yup.string()
        .oneOf(
          [Yup.ref("newPassword"), null],
          "Passwords must match with New Password"
        )
        .required("Confirm Password is required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      post(UPDATE_PASSWORD_URL, {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      })
        .then((response) => {
          const { message = "" } = response || {};
          toast.success(message, { toastId: "password" });
          formik.resetForm();
          clearsession();
        })
        .catch((res_error) => {
          const { response = {} } = res_error || {};
          const { data = {} } = response || {};
          const { error = "" } = data || {};
          toast.error(error, { toastId: "error" });
          if (res_error.response) {
            const { data } = res_error.response;
            const { detail = "", code = "", messages = [] } = data || {};
            if (messages && messages.length > 0) {
              const errorMessage = messages
                .map((msg) => msg.message)
                .join(", ");
              toast.error(errorMessage, { toastId: "error" });
            }
            if (code === "token_not_valid") {
              setTokenExpiry(true);
            } else {
              toast.error(detail || code || "An error occurred", {
                toastId: "error",
              });
            }
          } else if (error.request) {
            toast.error("No response received from the server", {
              toastId: "error",
            });
          } else {
            toast.error("An error occurred", { toastId: "error" });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <>
      {tokenExpiry ? (
        <ExpiryScreen
          setUploadedResumes={setUploadedResumes}
          setTokenExpiry={setTokenExpiry}
          setResponseData={setResponseData}
        />
      ) : (
        <div className="updatepassword">
          <div className="home-button-div">
            <ArrowBackIcon onClick={handleBack} />
            <Button
              label="Back to home"
              event={() => handleBack()}
              className="back-button"
            />
          </div>
          <div className="login">
            <div className="update-img-container">
              <img src={sideImg} alt="nothing" className="side-img" />
            </div>
            <form className="login-form">
              <h1 className="login-heading">Update Password </h1>
              <label className="oldpassword" htmlFor="OldPassword">
                OldPassword
              </label>
              <TextInput
                type={showOldPassword ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.oldPassword}
                name="oldPassword"
                error={formik.touched.oldPassword && formik.errors.oldPassword}
                errorMessage={formik.errors.oldPassword}
                passWordIcon={true}
                EyeIconToggle={() => EyeIconToggle("oldPassword")}
                open={showOldPassword}
                IconContainerClass="eye-icon"
                containerClassName="login-input-field"
                className="form-input"
                errorClassName="error-message-login"
                thumbsup={false}
              />
              <label className="newpassword" htmlFor="NewPassword">
                NewPassword
              </label>

              <TextInput
                type={showNewPassword ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
                name="newPassword"
                error={formik.touched.newPassword && formik.errors.newPassword}
                errorMessage={formik.errors.newPassword}
                passWordIcon={true}
                EyeIconToggle={() => EyeIconToggle("newPassword")}
                open={showNewPassword}
                IconContainerClass="eye-icon"
                containerClassName="login-input-field"
                className="form-input"
                errorClassName="error-message-login"
                thumbsup={false}
              />
              <label htmlFor="confirmPassword" className="confirmpassword">
                ConfirmPassword
              </label>
              <TextInput
                type={showConfirmPassword ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                name="confirmPassword"
                error={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                errorMessage={formik.errors.confirmPassword}
                passWordIcon={true}
                EyeIconToggle={() => EyeIconToggle("confirmPassword")}
                open={showConfirmPassword}
                IconContainerClass="eye-icon"
                containerClassName="login-input-field"
                className="form-input"
                errorClassName="error-message-login"
                thumbsup={false}
              />

              <Button
                event={formik.handleSubmit}
                type="submit"
                className={`login-button ${loading ? "update-loading" : ""}`}
                label={loading ? "Updating..." : "Update"}
                loading={loading}
              />
            </form>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}

export default UpdatePassword;
