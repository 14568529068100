import React from "react";
import "../Navbar/Navbar.css";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import UserLogo from "../Images/user.png";
import CancelIcon from "@mui/icons-material/Cancel";
import LogoutGif from "../Navbar/logout-animation.json";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { toast } from "react-toastify";
import Button from "../Typography/Button";
import { useNavigate } from "react-router-dom";
import DownArrow from "../Images/down-arrow.png";
import LogoutIcon from "../Images/logout-icon.png";
import UpdateHistoryIcon from "../Images/history-icon.png";
import updatePasswordIcon from "../Images/password-icon.png";
import vybogLogo from "../Images/Vybog Resume Lookbook Logo.png";
// import { LOGOUT_URL } from "../../helper/url_helper";
// import { post } from "../../helper/Api_helper";
import ExpiryScreen from "../Expiry-Login-Screen/ExpiryScreen";
function Navbar({
  setUploadedResumes,
  setTokenExpiry,
  setResponseData,
  Logout,
  setLogout,
  setisViewparsedata,
  tokenExpiry,
  setLoading,
  loading,
}) {
  const [dropdownVisible, setDropdownVisible] = React.useState(false);
  const dropdownRef = React.useRef(null);
  const navigate = useNavigate();
  const username = localStorage.getItem("user_name");
  // const [logoutLoading, setLogoutLoading] = React.useState(false);

  // const clearsession = async () => {
  //   setLogoutLoading(true);
  //   try {
  //     const responseData = await post(LOGOUT_URL);
  //     localStorage.clear("email");
  //     navigate("/");
  //     toast.success(responseData.message, { toastId: "success" });
  //     setUploadedResumes([]);
  //     setTokenExpiry(false);
  //     setResponseData([]);
  //   } catch (error) {
  //     if (error.response) {
  //       const { data } = error.response;
  //       const { detail = "", code = "", messages = [] } = data || {};
  //       if (messages && messages.length > 0) {
  //         const errorMessage = messages.map((msg) => msg.message).join(", ");
  //         toast.error(errorMessage, { toastId: "error" });
  //       }
  //       if (code === "token_not_valid") {
  //         setTokenExpiry(true);
  //       } else {
  //         toast.error(detail || code || "An error occurred", {
  //           toastId: "error",
  //         });
  //       }
  //     } else if (error.request) {
  //       toast.error("No response received from the server", {
  //         toastId: "error",
  //       });
  //     } else {
  //       toast.error("An error occurred", { toastId: "error" });
  //     }
  //   } finally {
  //     setLogoutLoading(false);
  //     setLoading(false);
  //     document.body.style.overflowY = "auto";
  //   }
  // };
  const clearsession = () => {
    localStorage.clear("email");
    navigate("/");
    toast.success("Logged out successfully");
    setUploadedResumes([]);
    setTokenExpiry(false);
    setResponseData([]);
    setLoading(false);
    document.body.style.overflowY = "auto";
  };
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  const handleclose = () => {
    setLogout(false);
  };
  const handleopen = () => {
    setLogout(true);
  };
  const handleUpdatepassword = () => {
    navigate("/update-password");
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleNavigate = () => {
    navigate("/upload-history");
    setisViewparsedata(false);
  };
  return (
    <>
      {tokenExpiry ? (
        <ExpiryScreen
          setUploadedResumes={setUploadedResumes}
          setTokenExpiry={setTokenExpiry}
          setResponseData={setResponseData}
        />
      ) : (
        <nav className="navbar">
          <a href="/" className="navbar-logo">
            <img src={vybogLogo} alt="nothing" className="vysystemlogo" />
          </a>
          <div
            className="navbar-links"
            style={loading ? { opacity: "0.5" } : { opacity: null }}
          >
            <div
              className="dropdown-container"
              onClick={toggleDropdown}
              ref={dropdownRef}
            >
              <Stack direction="row" spacing={2}>
                <Avatar src={UserLogo} className="user-avatar" />
                <p className="username">{username}</p>
              </Stack>
              <img src={DownArrow} alt="nothing" className="down-arrow" />
              {dropdownVisible && (
                <div className="dropdown-content">
                  <p onClick={() => handleNavigate()}>
                    {" "}
                    <img
                      src={UpdateHistoryIcon}
                      alt="nothing"
                      className="update-history-icon"
                    />
                    Upload History
                  </p>
                  <p onClick={() => handleUpdatepassword()}>
                    {" "}
                    <img
                      src={updatePasswordIcon}
                      alt="nothing"
                      className="update-history-icon"
                    />
                    Update Password
                  </p>
                  <p onClick={() => handleopen()}>
                    {" "}
                    <img
                      src={LogoutIcon}
                      alt="nothing"
                      className="logout-icon"
                    />
                    Logout
                  </p>
                </div>
              )}
            </div>
          </div>
        </nav>
      )}

      <>
        {Logout ? (
          <div className="delete-popup-container">
            <div className="delete-popup-outer">
              <div className="delete-popup-heading">
                <CancelIcon
                  className="close-icon"
                  onClick={handleclose}
                ></CancelIcon>
              </div>
              <div className="delete-popup">
                <div className="delete-popup-div">
                  <div className="close-div">
                    <Player
                      autoplay
                      loop
                      src={LogoutGif}
                      style={{ height: "200px", width: "350px" }}
                      className="logout-animation"
                    >
                      <Controls
                        visible={false}
                        buttons={["play", "repeat", "frame", "debug"]}
                      />
                    </Player>
                  </div>
                  <h1>Are you sure ?</h1>
                  <p> Do you want to Logout </p>
                  <Button
                    event={handleclose}
                    className="disagree"
                    label="Stay"
                  />
                  {/* <Button
                    event={() => clearsession()}
                    className={`agree ${logoutLoading ? "loading" : ""}`}
                    label={logoutLoading ? "Logout..." : "Logout"}
                    customLoading={logoutLoading}
                  /> */}
                  <Button
                    event={() => clearsession()}
                    className="agree"
                    label="Logout"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    </>
  );
}

export default Navbar;
