import { Navigate, Outlet } from "react-router-dom";
import React from "react";
export const PrivateRoute = (props) => {
  // const { setUploadedResumes } = props || {};
  // React.useEffect(() => {
  //   function isTokenExpired() {
  //     const expirationTime = localStorage.getItem("tokenExpiration");
  //     const currentTime = new Date().getTime();
  //     return currentTime > expirationTime;
  //   }

  //   if (isTokenExpired()) {
  //     setUploadedResumes([]);
  //     localStorage.removeItem("token");
  //     localStorage.removeItem("tokenExpiration");
  //     localStorage.removeItem("email");
  //     localStorage.removeItem("user_name");
  //   }
  // }, []);
  const token = localStorage.getItem("token");
  return token ? <Outlet /> : <Navigate to="/" />;
};
