export const ComparePageKeys = {
  CompareHeader: "Review parsing",
  ResumeHeader: "Resume",
  Name: "Name :",
  Email: "Email :",
  Contact: "Contact :",
  Skills: "Skills :",
  YearsofExperience: "Years of experience :",
  Experience: "Experience :",
  Education: "Education :",
  RecruiterNotes: "Recruiter notes :",
  Update: "Update",
  Compare: "Compare",
  Confirm: "Confirm",
  AddEducation: "Add education details",
  AddExperience: "Add experience details",
  DeleteExperience: "Delete experience details",
  DeleteEducation: "Delete education details",
};
