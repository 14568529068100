import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthRoute } from "./AuthRoute";
import { PrivateRoute } from "./PrivateRoute";
import DynamicProps from "../Component/DynamicProps/DynamicProps";
import LoginForm from "../Component/Login/Login";
import Errorpage from "../Component/ErrorPage/ErrorPage";
import CandidateFilesUpload from "../Component/CandidateFileUpload/CandidateFilesUpload";
import ExtractResumeData from "../Component/CandidateFileUpload/ExtractResumeData";
import ToastSnackBar from "../Component/Toast-SnackBar/ToastSnackBar";
import UploadHistory from "../Component/UploadHistory/UploadHistory";
import UpdatePassword from "../Component/UpdatePassword/UpdatePassword";
import Navbar from "../Component/Navbar/Navbar";
function AppRouter() {
  const {
    email,
    setemail,
    UploadedResumes,
    setUploadedResumes,
    Logout,
    setLogout,
    changesMade,
    setChangesMade,
    LoginFormChange,
    setLoginFormChange,
    responseData,
    setResponseData,
    loading,
    setLoading,
    base64,
    setBase64,
    tokenExpiry,
    setTokenExpiry,
    isRefetched,
    setisRefetched,
    isViewparsedata,
    setisViewparsedata,
    uploadedFileNames,
    setUploadedFileNames,
    uploadFileID,
    setUploadFileID,
    deleteDialog,
    setDeleteDialog,
    forgotPassword,
    setForgotPassword,
    resetPasswordPage,
    setResetPasswordPage,
  } = DynamicProps();

  const dynamicProps = {
    email,
    setemail,
    UploadedResumes,
    setUploadedResumes,
    Logout,
    setLogout,
    changesMade,
    setChangesMade,
    LoginFormChange,
    setLoginFormChange,
    responseData,
    setResponseData,
    loading,
    setLoading,
    base64,
    setBase64,
    tokenExpiry,
    setTokenExpiry,
    isRefetched,
    setisRefetched,
    isViewparsedata,
    setisViewparsedata,
    uploadedFileNames,
    setUploadedFileNames,
    uploadFileID,
    setUploadFileID,
    deleteDialog,
    setDeleteDialog,
    forgotPassword,
    setForgotPassword,
    resetPasswordPage,
    setResetPasswordPage,
  };
  const token = localStorage.getItem("token");

  React.useEffect(() => {
    document.body.style.overflowY =
      loading || Logout || deleteDialog ? "hidden" : "auto";
    document.body.style.overflowX = "hidden";
    return () => {
      document.body.style.overflowY = "auto";
      document.body.style.overflowX = "hidden";
    };
  }, [loading, Logout, deleteDialog]);

  return (
    <>
      <BrowserRouter>
        {token && <Navbar {...dynamicProps} />}
        <Routes>
          <Route element={<AuthRoute />}>
            <Route path="/" element={<LoginForm {...dynamicProps} />} />
          </Route>
          <Route element={<PrivateRoute {...dynamicProps} />}>
            <Route
              path="/file-upload"
              element={<CandidateFilesUpload {...dynamicProps} />}
            />
            <Route
              path="/extract-resume-page"
              element={<ExtractResumeData {...dynamicProps} />}
            />
            <Route
              path="/upload-history"
              element={<UploadHistory {...dynamicProps} />}
            />
            <Route
              path="/update-password"
              element={<UpdatePassword {...dynamicProps} />}
            />
          </Route>
          <Route
            path="*"
            element={token ? <Errorpage /> : <Navigate to="/" />}
          />
        </Routes>
      </BrowserRouter>{" "}
      <ToastSnackBar />
    </>
  );
}

export default AppRouter;
