import React from 'react';
import Button from '../Typography/Button';
import TextInput from "../Typography/TextField";
import loginanimation from "../Login/newanimation.json";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./ForgotPassword.css"
import "../UpdatePassword/UpdatePassword.css"
import { useFormik } from "formik";
import * as Yup from "yup";
import ResetPassword from '../ResetPassword/ResetPassword';
import Vylabslogo from "../Images/Vylabs Logo.png";
import vybogLogoRP from "../Images/Vybog Resume Lookbook Logo.png";
import { toast } from 'react-toastify';

function ForgotPassword({setForgotPassword,resetPasswordPage,setResetPasswordPage}) {

    const validationSchema = Yup.object().shape({
        email: Yup.string()
          .email("Invalid email address")
          .required("Please enter your email address")
      });

  const handleBack = () => {
    setForgotPassword(false);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("Forgot Password",values);
      toast.success("Code send to email successfully",{toastId:"success"})
      setResetPasswordPage(true);
    },
  });
  return (
    <>
      {resetPasswordPage ? (
        <ResetPassword
          setResetPasswordPage={setResetPasswordPage}
          setForgotPassword={setForgotPassword}
        />
      ) : (
        <div className='forgot-password'>
          <div className="forgot-password-container">
            <div className="background-container">
              <div className="welcome-text">
                Welcome to Vybog <br />
                Resume Lookbook
              </div>
              <div className="login-animation">
                <Player autoplay loop src={loginanimation}>
                  <Controls
                    visible={false}
                    buttons={["play", "repeat", "frame", "debug"]}
                  />
                </Player>
              </div>
            </div>

            <form
              className="forgot-password-form"
              onSubmit={formik.handleSubmit}
            >
                <img
                  className="lookbook-logo"
                  alt="lookbook-logo"
                  src={vybogLogoRP}
                />
              <div className="password-back-button-div">
                <ArrowBackIcon onClick={handleBack} />
                <Button
                  label="Login"
                  event={() => handleBack()}
                  className="password-back-button"
                />
              </div>
            
              <h1 className="login-heading">Forgot Password</h1>
              <label className="Email-label" htmlFor="email">
                Email address
              </label>
              <TextInput
                id="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                name="email"
                labelFor="email"
                error={formik.touched.email && formik.errors.email}
                errorMessage={formik.errors.email}
                containerClassName="login-input-field"
                className="form-input"
                errorClassName="error-message-login"
                thumbsup={false}
              />
              <div className='forgotPasswordButtons'>
                <div className='back-button-forgot'>
                <Button
                type="submit"
                label="Back"
                className="login-button"
                event={handleBack}
              />
                </div>
                <div className='reset-button'>
                <Button
                type="submit"
                label="Reset"
                className="login-button"
                //   event={formik.handleSubmit}
              />
              </div>
                </div>
             
              <div className="password-copyright">
                <p className="cpyrt-text">Powered by</p>
                <a href="https://vylabs.ai/" target="blank">
                  <img className="logo2" src={Vylabslogo} alt="vybog logo" />
                </a>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default ForgotPassword;
