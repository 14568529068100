// // Demo-URL
// export const DEMO_LOGIN_URL = "/api/v1/login/";
// export const DEMO_REGISTER_URL = "/api/v1/register/";
// export const DEMO_PARSE_RESUME_URL = "/parser/v1/parse-resumes/";
// export const DEMO_DELETE_URL = "/parser/v1/delete-file/";
// export const DEMO_GET_URL = "/parser/v1/get-file/";
// export const DEMO_UPDATE_URL = "/parser/v1/update-file/";

import config from "./config";

const REACT_APP_ENV = process.env.REACT_APP_ENV || "demo";

export const BASE_URL =
  REACT_APP_ENV === "demo"
    ? config.demo.baseURL
    : config.development.baseURL;

export const REGISTER_URL =
  REACT_APP_ENV === "demo"
    ? config.demo.registerUrl
    : config.development.registerUrl;

export const LOGIN_URL =
  REACT_APP_ENV === "demo" ? config.demo.loginUrl : config.development.loginUrl;

export const PARSED_RESUME_URL =
  REACT_APP_ENV === "demo"
    ? config.demo.parseResumeUrl
    : config.development.parseResumeUrl;

  export const PROCESS_UPLOAD_FILE =
    REACT_APP_ENV === "demo"
      ? config.demo.processUploadedFile
      : config.development.processUploadedFile;

export const DELETE_URL =
  REACT_APP_ENV === "demo"
    ? config.demo.deleteUrl
    : config.development.deleteUrl;

export const UPDATE_URL =
  REACT_APP_ENV === "demo"
    ? config.demo.updateUrl
    : config.development.updateUrl;

export const GET_URL =
  REACT_APP_ENV === "demo" ? config.demo.getUrl : config.development.getUrl;

export const UPDATE_PASSWORD_URL =
  REACT_APP_ENV === "demo"
    ? config.demo.updatePasswordUrl
    : config.development.updatePasswordUrl;

export const LOGOUT_URL =
  REACT_APP_ENV === "demo"
    ? config.demo.logoutUrl
    : config.development.logoutUrl;

export const USER_FILE_HISTORY_URL =
  REACT_APP_ENV === "demo"
    ? config.demo.userFileHistoryUrl
    : config.development.userFileHistoryUrl;

export const USER_DETAILS_GET_URL =
  REACT_APP_ENV === "demo"
    ? config.demo.userDetailsGetUrl
    : config.development.userDetailsGetUrl;

export const REFRESH_TOKEN_GET_URL =
  REACT_APP_ENV === "demo"
    ? config.demo.refreshTokenGetUrl
    : config.development.refreshTokenGetUrl;

export const WEB_SOCKET_URL =
  REACT_APP_ENV === "demo"
    ? config.demo.WebSocketUrl
    : config.development.WebSocketUrl;

export const THUMBS_UP_URL =
  REACT_APP_ENV === "demo"
    ? config.demo.thumbsupUrl
    : config.development.thumbsupUrl;
