import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./LoginForm.css";
import { toast } from "react-toastify";
import Vylabslogo from "../Images/Vylabs Logo.png";
import vybogLogoRP from "../Images/Vybog Resume Lookbook Logo.png";
import TextInput from "../Typography/TextField";
import Button from "../Typography/Button";
import Signup from "../SignUp/SignUp";
import { get, post } from "../../helper/Api_helper";
import {
  LOGIN_URL,
  REFRESH_TOKEN_GET_URL,
  USER_DETAILS_GET_URL,
} from "../../helper/url_helper";
import loginanimation from "../Login/newanimation.json";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import GlobalEnterKeyHandler from "../Typography/GlobalEnterKeyHandler";
import ForgotPassword from "../ForgotPassword/ForgotPassword";

function Login(props) {
  const {
    setemail,
    LoginFormChange,
    setLoginFormChange,
    setLogout,
    setLoading,
    loading,
    forgotPassword,
    setForgotPassword = () => "",
    resetPasswordPage,
    setResetPasswordPage,
  } = props || {};
  const [showPassword, setshowPassword] = useState(false);
  const [accessTokenExpiresAt, setAccessTokenExpiresAt] = useState(null);

  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Please enter your email address"),
    password: Yup.string().required("Please enter your password"),
  });
  GlobalEnterKeyHandler();

  const refreshToken = () => {
    post(REFRESH_TOKEN_GET_URL, {
      // access_token: localStorage.getItem("token"),
      refresh_token: localStorage.getItem("refresh_token"),
    })
      .then((response) => {
        const { access_token, access_token_expires_at } = response || {};
        localStorage.setItem("token", access_token);
        // localStorage.setItem("refresh_token", refresh);
        setAccessTokenExpiresAt(access_token_expires_at);
        console.log("REFRESH_TOKEN_GET_URL", response);
      })
      .catch((error) => {
        console.log("REFRESH_TOKEN_GET_URL", error);
      });
  };

  useEffect(() => {
    if (accessTokenExpiresAt) {
      const currentTime = new Date().getTime();
      const expiresIn = new Date(accessTokenExpiresAt).getTime() - currentTime;

      const timer = setTimeout(() => {
        refreshToken();
      }, expiresIn - 60000);
      return () => clearTimeout(timer);
    }
  }, [accessTokenExpiresAt]);

  const fetchData = async (userId) => {
    try {
      const response = await get(USER_DETAILS_GET_URL + userId);
      console.log("get call response ", response);
      // const { email = "", full_name = "" } = response || {};
      // localStorage.setItem("email", email);
      // localStorage.setItem("user_name", full_name);
      // console.log(full_name);
    } catch (error) {
      console.log(error);
    }
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      // const { email } = values;
      post(LOGIN_URL, {
        email: formik.values.email,
        password: formik.values.password,
      })
        .then((response) => {
          const { user, access_token, refresh_token, access_token_expires_at } =
            response;
          const { name = "", email = "" } = user || {};
          localStorage.setItem("token", access_token);
          localStorage.setItem("refresh_token", refresh_token);
          localStorage.setItem("email", email);
          localStorage.setItem("user_name", name);
          setAccessTokenExpiresAt(access_token_expires_at);
          toast.success("Login Sucessfully", { toastId: "login" });
          navigate("/file-upload");
          setemail(email);
          setLogout(false);
          fetchData(user.id);
          // setTimeout(() => {
          //   refreshToken();
          // }, 1 * 60 * 1000);
        })
        .catch((res_error) => {
          const { response = {} } = res_error || {};
          const { data = {} } = response || {};
          const { error = "" } = data || {};
          console.log("login call error", error);
          toast.error(error, { toastId: "error" });
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const EyeIconToggle = () => {
    setshowPassword(!showPassword);
  };
  const handlePageChange = () => {
    if (!loading) {
      setLoading(false);
      setLoginFormChange(true);
      document.body.style.overflowY = "auto";
      formik.resetForm();
    }
  };
  const handleForgotPassword = () => {
    if (!loading) {
      setForgotPassword(true);
    }
  };
  console.log("login forgotPassword", forgotPassword);
  return (
    <div>
      {forgotPassword ? (
        <ForgotPassword
          setForgotPassword={setForgotPassword}
          resetPasswordPage={resetPasswordPage}
          setResetPasswordPage={setResetPasswordPage}
        />
      ) : (
        <div>
          {LoginFormChange ? (
            <Signup
              setLoginFormChange={setLoginFormChange}
              setLoading={setLoading}
              loading={loading}
            />
          ) : (
            <div className="login">
              <div className="background-container">
                <div className="welcome-text">
                  Welcome to Vybog <br />
                  Resume Lookbook
                </div>
                <div className="login-animation">
                  <Player autoplay loop src={loginanimation}>
                    <Controls
                      visible={false}
                      buttons={["play", "repeat", "frame", "debug"]}
                    />
                  </Player>
                </div>
              </div>
              <form className="login-form">
                <img
                  className="lookbook-logo"
                  alt="lookbook-logo"
                  src={vybogLogoRP}
                />

                <h1 className="login-heading">Login</h1>
                <label className="Email-label" htmlFor="email">
                  Email address
                </label>
                <TextInput
                  id="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  name="email"
                  labelFor="email"
                  error={formik.touched.email && formik.errors.email}
                  errorMessage={formik.errors.email}
                  containerClassName="login-input-field"
                  className="form-input"
                  errorClassName="error-message-login"
                  thumbsup={false}
                />
                <label className="Password-label" htmlFor="password">
                  Password
                </label>
                <TextInput
                  id="password"
                  type={showPassword ? "text" : "password"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  name="password"
                  labelFor="password"
                  error={formik.touched.password && formik.errors.password}
                  errorMessage={formik.errors.password}
                  passWordIcon={true}
                  EyeIconToggle={EyeIconToggle}
                  IconContainerClass="eye-icon"
                  open={showPassword}
                  containerClassName="login-input-field"
                  className="form-input"
                  errorClassName="error-message-login"
                  thumbsup={false}
                />

                <Button
                  event={formik.handleSubmit}
                  type="submit"
                  className={`login-button ${loading ? "loading" : ""}`}
                  label={loading ? "Login..." : "Login"}
                  loading={loading}
                />
                <div className="usertag-div">
                  <h5 className="newuser-tag">
                    New user?{" "}
                    <span onClick={handlePageChange}>Create free account</span>
                  </h5>
                  <h5 className="newuser-tag-forgot-password">
                    Forgot password?{" "}
                    <span onClick={handleForgotPassword}>Click here</span>
                  </h5>
                </div>
                <div className="copyright">
                  <p className="cpyrt-text">Powered by</p>
                  <a href="https://vylabs.ai/" target="blank">
                    <img className="logo2" src={Vylabslogo} alt="vybog logo" />
                  </a>
                </div>
              </form>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Login;
