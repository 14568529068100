const config = {
  development: {
    baseURL: "https://api-stage.vylabsapi.com",
    registerUrl: "/users/signup/",
    loginUrl: "/users/signin/",
    parseResumeUrl: "/files/get-upload-urls/",
    processUploadedFile: "/resumes/process-uploaded-file/",
    updatePasswordUrl: "/users/change-password/",
    logoutUrl: "/users/logout/",
    userFileHistoryUrl: "/resumes/list/",
    userDetailsGetUrl: "/users/user/",
    refreshTokenGetUrl: "/users/renew-access-token/",
    deleteUrl: "/file/delete-file/",
    thumbsupUrl: "/file/thumbs-up/",
    updateUrl: "/parser/v1/update-file/",
    getUrl: "/parser/v1/get-file/",
    WebSocketUrl: "/ws/user/",
  },
  demo: {
    baseURL: "https://api-stage.vylabsapi.com",
    registerUrl: "/api/v1/register/",
    loginUrl: "/api/v1/login/",
    parseResumeUrl: "/files/get-upload-urls/",
    processUploadedFile: "/resumes/process-uploaded-file/",
    updatePasswordUrl: "/users/update-password/",
    logoutUrl: "/users/logout/",
    userFileHistoryUrl: "/resumes/list/",
    userDetailsGetUrl: "/users/user/",
    refreshTokenGetUrl: "/users/refresh-token/",
    deleteUrl: "/file/delete-file/",
    thumbsupUrl: "/file/thumbs-up/",
    updateUrl: "/parser/v1/update-file/",
    getUrl: "/parser/v1/get-file/",
    WebSocketUrl: "/ws/user/",
  },
  production: {
    baseURL: "http://localhost:5000",
  },
};

export default config;
