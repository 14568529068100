import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextField from "../Typography/TextField";
import { ComparePageKeys } from "../Typography/Information";
import Button from "../Typography/Button";
import Tooltip from "@mui/material/Tooltip";
// import Loader from "../Pre-Loader/Loader";
import pdf from "../../assets/Resume.pdf";
import {
  SideHeadings,
  MainSideHeadings,
  TextArea,
} from "../Typography/SideHeadings";
import { Chip } from "@mui/material";
import { usePdf } from "@mikecousins/react-pdf";
import { toast } from "react-toastify";
import ExpiryScreen from "../Expiry-Login-Screen/ExpiryScreen";
// import DocxViewer from "../Typography/DocViewer";
import ThumbsUpDown from "../Typography/ThumbsUpDown";
import { post } from "../../helper/Api_helper";
import { THUMBS_UP_URL } from "../../helper/url_helper";
import CustomAutocomplete from "../AutoComplete/AutoComplete";
import CustomDatePicker from "../DatePicker/DatePicker";
import DynamicCheckbox from "../CheckBox/CheckBox";
import staticOptions from "../../Component/Compare-Popup/staticData.json";

function ComparePopup({
  formik,
  handleSkillChange,
  selectedResumeIndex,
  handleclosePopup,
  setChangesMade,
  setNewlyAddedSkills,
  newlyAddedSkills,
  existingSkills,
  setExistingSkills,
  newSkill,
  setNewSkill,
  file_url,
  file_type,
  parsed_resumes,
  experienceSections,
  setExperienceSections,
  educationSections,
  setEducationSections,
  updated_Experience,
  updated_Education,
  setUploadedResumes,
  setTokenExpiry,
  setResponseData,
  tokenExpiry,
  dateError,
  setDateError,
  Thumbsup,
  setThumbsup,
  Thumbsdown,
  setThumbsDown,
  uploadFileID,
}) {
  const { staticData = {} } = staticOptions;
  const { city = [], country = [] } = staticData;
  const [text, setText] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [currentlyStudyingIndex, setCurrentlyStudyingIndex] = useState(-1);
  const [currentlyWorkingIndex, setcurrentlyWorkingIndex] = useState(-1);
  const canvasRef = React.useRef(null);
  const { pdfDocument } = usePdf({
    file: pdf,
    page,
    canvasRef,
  });

  React.useEffect(() => {
    fetch(file_url)
      .then((response) => response.text())
      .then((data) => {
        setText(data);
      });
  }, [file_url, text]);

  const scrollToErrorField = () => {
    const findNestedErrorField = (errors) => {
      for (const field in errors) {
        if (errors[field] && typeof errors[field] === "object") {
          const nestedErrorField = findNestedErrorField(errors[field]);
          if (nestedErrorField) {
            return `${field}.${nestedErrorField}`;
          }
        } else if (errors[field]) {
          return field;
        }
      }
      return null;
    };

    const firstErrorField = findNestedErrorField(formik.errors);

    if (firstErrorField) {
      const errorElement = document.querySelector(
        `[name="${firstErrorField}"]`
      );

      if (errorElement) {
        setTimeout(() => {
          errorElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }, 0);
      } else {
        const dynamicErrorElement = document.querySelector(
          `[name^="${firstErrorField.split(".")[0]}"]`
        );

        if (dynamicErrorElement) {
          setTimeout(() => {
            dynamicErrorElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            });
          }, 0);
        }
      }
    }
  };
  const handleAddSkill = () => {
    if (newSkill.trim() !== "") {
      const trimmedLowercaseNewSkill = newSkill
        .toLowerCase()
        .replace(/\s+/g, "");
      const trimmedLowercaseExistingSkills = existingSkills.map((skill) =>
        skill.toLowerCase().replace(/\s+/g, "")
      );
      if (trimmedLowercaseExistingSkills.includes(trimmedLowercaseNewSkill)) {
        toast.warning("Skill already exists", { toastId: "skill" });
      } else {
        const newSkills = [...formik.values.skills, newSkill.trim()];
        setExistingSkills(newSkills);
        formik.setFieldValue("skills", newSkills);
        setNewlyAddedSkills([...newlyAddedSkills, newSkill.trim()]);
        setNewSkill("");
        setChangesMade(true);
      }
    }
  };

  const handleRemoveSkill = (skill) => {
    const updatedSkills = formik.values.skills.filter(
      (existingSkill) => existingSkill !== skill
    );
    setExistingSkills(updatedSkills);
    setNewlyAddedSkills(
      newlyAddedSkills.filter((addedSkill) => addedSkill !== skill)
    );

    formik.setFieldValue("skills", updatedSkills);
    setChangesMade(true);
  };
  const addingContentStructures = {
    educationHistory: {
      institute: "",
      instituteLocationCity: "",
      instituteLocationCountry: "",
      degree: "",
      fieldOfStudy: "",
      graduationDate: "",
      GPAOrTestScore: "",
      currentlyStudying: false,
    },
    certificationsAndLicenses: {
      certificationName: "",
      issuingOrganization: "",
      issueDate: "",
    },
    careerHistory: {
      jobTitle: "",
      jobCompany: "",
      jobLocationCity: "",
      jobLocationCountry: "",
      startDate: "",
      endDate: "",
      currentlyWorking: false,
    },

    candidatesProjects: {
      projectName: "",
      projectRole: "",
      projectDescription: "",
      startDate: "",
      endDate: "",
    },
  };
  const addContainer = (sectionKey) => {
    const section = formik.values[sectionKey];
    const lastItem = section[section.length - 1];
    const isLastItemEmpty = Object.values(lastItem).some(
      (value) => value === ""
    );

    if (!isLastItemEmpty) {
      formik.setValues({
        ...formik.values,
        [sectionKey]: [...section, addingContentStructures[sectionKey]],
      });
    } else {
      toast.warn(
        "Please fill the existing container before adding a new one.",
        {
          toastId: "warn-add-container",
        }
      );
    }
  };

  const removeContainer = (sectionKey, index) => {
    const updatedSection = [...formik.values[sectionKey]];
    updatedSection.splice(index, 1);
    formik.setValues({
      ...formik.values,
      [sectionKey]: updatedSection,
    });
  };

  console.log("All Container values:", formik.values);
  const handleThumbsUpClick = async (thumbsUpState, thumbsUpType, isUp) => {
    try {
      const response = await post(THUMBS_UP_URL, {
        file: uploadFileID[selectedResumeIndex],
        type: `resume_${thumbsUpType}_thumbs_up`,
        up: isUp ? thumbsUpState : !thumbsUpState,
      });
      console.log(
        "resume_thumbsUpType_thumbs_up",
        `resume_${thumbsUpType}_thumbs_up`
      );
      const { message = "" } = response;
      console.log("thumbs up response", message);
      toast.success(message, { toastId: "success" });
    } catch (error) {
      console.error("File upload error:", error);
      if (error.response) {
        const { data } = error.response;
        const { detail = "", code = "", messages = [] } = data || {};
        if (messages && messages.length > 0) {
          const errorMessage = messages.map((msg) => msg.message).join(", ");
          toast.error(errorMessage, { toastId: "error" });
        }
        if (code === "token_not_valid") {
          setTokenExpiry(true);
        } else {
          toast.error(detail || code || "An error occurred", {
            toastId: "error",
          });
        }
      } else if (error.request) {
        toast.error("No response received from the server", {
          toastId: "error",
        });
      } else {
        toast.error("An error occurred", { toastId: "error" });
      }
    }
  };

  const handleCheckboxChange = (fieldName, index, fieldKey) => {
    if (index === currentlyStudyingIndex) {
      setCurrentlyStudyingIndex(-1);
    } else {
      setCurrentlyStudyingIndex(index);
    }
    if (index === currentlyWorkingIndex) {
      setcurrentlyWorkingIndex(-1);
    } else {
      setcurrentlyWorkingIndex(index);
    }
    const currentValue = formik.values[fieldName][index][fieldKey];
    formik.setFieldValue(`${fieldName}[${index}].${fieldKey}`, !currentValue);

    for (let i = 0; i < formik.values[fieldName].length; i++) {
      if (i !== index) {
        formik.setFieldValue(`${fieldName}[${i}].${fieldKey}`, false);
      }
    }
  };

  return (
    <>
      {tokenExpiry ? (
        <ExpiryScreen
          setUploadedResumes={setUploadedResumes}
          setTokenExpiry={setTokenExpiry}
          setResponseData={setResponseData}
        />
      ) : (
        <form>
          <div className="dialog-outer-container">
            <div className="dialog-container">
              <div className="resume-data">
                <div className="person-details">
                  <div className="person-data">
                    <div className="Extracted-div">
                      <h2>{ComparePageKeys.CompareHeader}</h2>
                    </div>
                    <div className="data thumbsup compare-information">
                      <MainSideHeadings label={ComparePageKeys.Name} />
                      <TextField
                        name="basicInfo.name"
                        value={formik.values.basicInfo?.name}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.basicInfo?.name ||
                          formik.errors.basicInfo?.name
                        }
                        errorMessage={formik.errors.basicInfo?.name}
                        errorClassName="error-message"
                        className="compare-textfield"
                        containerClassName="education-textfield"
                        icon={null}
                        thumbsup={false}
                      />{" "}
                      <ThumbsUpDown
                        Thumbsup={Thumbsup}
                        Thumbsdown={Thumbsdown}
                        onThumbsUpClick={(state, type) =>
                          handleThumbsUpClick(state, type, true)
                        }
                        onThumbsDownClick={(state, type) =>
                          handleThumbsUpClick(state, type, false)
                        }
                        thumbsUpType="name"
                      />
                    </div>
                    <div className="data thumbsup compare-information">
                      <MainSideHeadings label={ComparePageKeys.Email} />
                      <TextField
                        type="email"
                        name="basicInfo.email"
                        value={formik.values.basicInfo?.email}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.basicInfo?.email ||
                          formik.errors.basicInfo?.email
                        }
                        errorMessage={formik.errors.basicInfo?.email}
                        errorClassName="error-message"
                        className="compare-textfield"
                        containerClassName="education-textfield"
                        thumbsup={false}
                      />{" "}
                      <ThumbsUpDown
                        Thumbsup={Thumbsup}
                        Thumbsdown={Thumbsdown}
                        onThumbsUpClick={(state, type) =>
                          handleThumbsUpClick(state, type, true)
                        }
                        onThumbsDownClick={(state, type) =>
                          handleThumbsUpClick(state, type, false)
                        }
                        thumbsUpType="email"
                      />
                    </div>
                    <div className="data thumbsup contact">
                      <MainSideHeadings label={ComparePageKeys.Contact} />
                      <TextField
                        name="basicInfo.phoneNumber"
                        value={formik.values.basicInfo?.phoneNumber}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.basicInfo?.phoneNumber &&
                          formik.errors.basicInfo?.phoneNumber
                        }
                        errorMessage={formik.errors.basicInfo?.phoneNumber}
                        errorClassName="error-message"
                        className="compare-textfield"
                        containerClassName="education-textfield"
                        thumbsup={false}
                      />
                      <ThumbsUpDown
                        Thumbsup={Thumbsup}
                        Thumbsdown={Thumbsdown}
                        onThumbsUpClick={(state, type) =>
                          handleThumbsUpClick(state, type, true)
                        }
                        onThumbsDownClick={(state, type) =>
                          handleThumbsUpClick(state, type, false)
                        }
                        thumbsUpType="contact"
                      />
                    </div>
                    <div className="data thumbsup compare-information">
                      <MainSideHeadings label={"City :"} />
                      <CustomAutocomplete
                        InputWrapperStyle={{
                          padding: "2px 7px",
                          width: "30vw",
                        }}
                        text={true}
                        defaultValue={
                          formik.values.basicInfo?.currentLocationCity
                        }
                        listBoxStyle={{ width: "30vw", marginLeft: "-8px" }}
                        autocompleteOuterDiv={{ marginLeft: "-8px" }}
                        freeSolo={true}
                        placeholder="City"
                        options={city}
                        onChange={(newValue) => {
                          formik.setFieldValue(
                            "basicInfo.currentLocationCity",
                            newValue
                          );
                        }}
                        onBlur={formik.handleBlur}
                        showDownArrowIcon={true}
                        errorMessage={
                          formik.errors.basicInfo?.currentLocationCity
                        }
                        error={
                          formik.touched.basicInfo?.currentLocationCity &&
                          formik.errors.basicInfo?.currentLocationCity
                        }
                      />
                      <ThumbsUpDown
                        Thumbsup={Thumbsup}
                        Thumbsdown={Thumbsdown}
                        onThumbsUpClick={(state, type) =>
                          handleThumbsUpClick(state, type, true)
                        }
                        onThumbsDownClick={(state, type) =>
                          handleThumbsUpClick(state, type, false)
                        }
                        thumbsUpType={"currentLocationCity"}
                      />
                    </div>
                    <div className="data thumbsup compare-information">
                      <MainSideHeadings label={"Country :"} />
                      <CustomAutocomplete
                        InputWrapperStyle={{
                          padding: "2px 7px",
                          width: "30vw",
                        }}
                        text={true}
                        defaultValue={
                          formik.values.basicInfo?.currentLocationCountry
                        }
                        listBoxStyle={{ width: "30vw", marginLeft: "-8px" }}
                        autocompleteOuterDiv={{ marginLeft: "-8px" }}
                        freeSolo={true}
                        placeholder="Country"
                        options={country}
                        onChange={(newValue) => {
                          formik.setFieldValue(
                            "basicInfo.currentLocationCountry",
                            newValue
                          );
                        }}
                        showDownArrowIcon={true}
                        errorMessage={
                          formik.errors.basicInfo?.currentLocationCountry
                        }
                        error={
                          formik.touched.basicInfo?.currentLocationCountry &&
                          formik.errors.basicInfo?.currentLocationCountry
                        }
                      />
                      <ThumbsUpDown
                        Thumbsup={Thumbsup}
                        Thumbsdown={Thumbsdown}
                        onThumbsUpClick={(state, type) =>
                          handleThumbsUpClick(state, type, true)
                        }
                        onThumbsDownClick={(state, type) =>
                          handleThumbsUpClick(state, type, false)
                        }
                        thumbsUpType={"currentLocationCountry"}
                      />
                    </div>
                    <div className="data thumbsup compare-information">
                      <MainSideHeadings label={"Personal Website Link :"} />
                      <TextField
                        name="basicInfo.personalWebsiteLink"
                        value={formik.values.basicInfo?.personalWebsiteLink}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        className="compare-textfield"
                        containerClassName="education-textfield"
                        thumbsup={false}
                        errorClassName="error-message"
                        errorMessage={
                          formik.errors.basicInfo?.personalWebsiteLink
                        }
                        error={
                          formik.touched.basicInfo?.personalWebsiteLink &&
                          formik.errors.basicInfo?.personalWebsiteLink
                        }
                      />{" "}
                      <ThumbsUpDown
                        Thumbsup={Thumbsup}
                        Thumbsdown={Thumbsdown}
                        onThumbsUpClick={(state, type) =>
                          handleThumbsUpClick(state, type, true)
                        }
                        onThumbsDownClick={(state, type) =>
                          handleThumbsUpClick(state, type, false)
                        }
                        thumbsUpType={"personalWebsiteLink"}
                      />
                    </div>
                    <div className="data thumbsup compare-information">
                      <MainSideHeadings label={"LinkedIn WebsiteLink :"} />
                      <TextField
                        name="basicInfo.linkedInWebsiteLink"
                        value={formik.values.basicInfo?.linkedInWebsiteLink}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        errorClassName="error-message"
                        errorMessage={
                          formik.errors.basicInfo?.linkedInWebsiteLink
                        }
                        error={
                          formik.touched.basicInfo?.linkedInWebsiteLink &&
                          formik.errors.basicInfo?.linkedInWebsiteLink
                        }
                        className="compare-textfield"
                        containerClassName="education-textfield"
                        thumbsup={false}
                      />{" "}
                      <ThumbsUpDown
                        Thumbsup={Thumbsup}
                        Thumbsdown={Thumbsdown}
                        onThumbsUpClick={(state, type) =>
                          handleThumbsUpClick(state, type, true)
                        }
                        onThumbsDownClick={(state, type) =>
                          handleThumbsUpClick(state, type, false)
                        }
                        thumbsUpType={"linkedInWebsiteLink"}
                      />
                    </div>
                    <div className="data data-skills thumbsup compare-information">
                      <MainSideHeadings label={ComparePageKeys.Skills} />
                      <div className="skill-container">
                        <div className="skills" name="skills">
                          {formik.values.skills.map((skill, index) => (
                            <Chip
                              key={index}
                              label={skill}
                              onDelete={() => handleRemoveSkill(skill)}
                              onChange={handleSkillChange}
                              variant="outlined"
                              style={{
                                marginRight: "8px",
                                marginBottom: "8px",
                                backgroundColor: newlyAddedSkills.includes(
                                  skill
                                )
                                  ? "green"
                                  : "#f1f7ff",
                                color: newlyAddedSkills.includes(skill)
                                  ? "white"
                                  : "black",
                                textTransform: "capitalize",
                              }}
                            />
                          ))}
                        </div>
                        {formik.values.skills.length < 1 ? (
                          <div className="skill-error-message">
                            {/* {formik.errors.skills} */}
                            At least one skill is required
                          </div>
                        ) : null}
                      </div>
                      <ThumbsUpDown
                        Thumbsup={Thumbsup}
                        Thumbsdown={Thumbsdown}
                        onThumbsUpClick={(state, type) =>
                          handleThumbsUpClick(state, type, true)
                        }
                        onThumbsDownClick={(state, type) =>
                          handleThumbsUpClick(state, type, false)
                        }
                        thumbsUpType="skills"
                      />
                    </div>
                    <div className="data">
                      <label className="compare-label">Add skill:</label>
                      <div className="addskill-container">
                        <TextField
                          variant="outlined"
                          value={newSkill}
                          onChange={(e) => setNewSkill(e.target.value)}
                          className="add-skill"
                          thumbsup={false}
                        />
                        <Button
                          className="add-skill-btn"
                          label="Add skill"
                          event={handleAddSkill}
                        />
                      </div>
                    </div>

                    <div className="data  thumbsup yearsofexperience compare-information">
                      <MainSideHeadings
                        label={ComparePageKeys.YearsofExperience}
                      />
                      <div className="overall-experience-container">
                        <TextField
                          name="basicInfo.yearsOfExperience"
                          value={formik.values.basicInfo?.yearsOfExperience}
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.basicInfo?.yearsOfExperience &&
                            formik.errors.basicInfo?.yearsOfExperience
                          }
                          errorMessage={
                            formik.errors.basicInfo?.yearsOfExperience
                          }
                          containerClassName="education-textfield overall_experience"
                          errorClassName="error-message"
                          className="compare-textfield compare-experience "
                          thumbsup={false}
                        />
                        <ThumbsUpDown
                          Thumbsup={Thumbsup}
                          Thumbsdown={Thumbsdown}
                          onThumbsUpClick={(state, type) =>
                            handleThumbsUpClick(state, type, true)
                          }
                          onThumbsDownClick={(state, type) =>
                            handleThumbsUpClick(state, type, false)
                          }
                          thumbsUpType="yearsOfExperience"
                        />
                      </div>
                    </div>

                    {formik.values.educationHistory &&
                      formik.values.educationHistory.map((education, index) => (
                        <div
                          className="data data-skills"
                          key={`education-${index}`}
                        >
                          <MainSideHeadings label={"Education :"} />
                          <div className="content">
                            <SideHeadings
                              className="side-headings"
                              label="Institute"
                            />
                            <div className="data thumbsup compare-information">
                              <TextField
                                containerClassName="education-textfield"
                                className="compare-textfield"
                                name={`educationHistory[${index}].institute`}
                                value={education.institute}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                }}
                                errorClassName="error-message"
                                icon={""}
                                error={
                                  formik.errors.educationHistory &&
                                  formik.errors.educationHistory[index] &&
                                  formik.errors.educationHistory[index]
                                    .institute
                                }
                                errorMessage={
                                  formik.errors.educationHistory &&
                                  formik.errors.educationHistory[index] &&
                                  formik.errors.educationHistory[index]
                                    .institute
                                }
                              />
                              <ThumbsUpDown
                                Thumbsup={Thumbsup}
                                Thumbsdown={Thumbsdown}
                                onThumbsUpClick={(state, type) =>
                                  handleThumbsUpClick(state, type, true)
                                }
                                onThumbsDownClick={(state, type) =>
                                  handleThumbsUpClick(state, type, false)
                                }
                                thumbsUpType={`educationHistory[${index}].institute`}
                              />
                            </div>
                            <SideHeadings
                              className="side-headings"
                              label="Institute City Location"
                            />
                            <div className="data thumbsup compare-information">
                              <CustomAutocomplete
                                key={`education-${index}-city`}
                                InputWrapperStyle={{
                                  padding: "2px 7px",
                                  width: "30vw",
                                }}
                                listBoxStyle={{
                                  width: "30vw",
                                }}
                                freeSolo={true}
                                text={true}
                                defaultValue={education?.instituteLocationCity}
                                placeholder="City"
                                options={city}
                                onChange={(newValue) => {
                                  formik.setFieldValue(
                                    `educationHistory[${index}].instituteLocationCity`,
                                    newValue
                                  );
                                }}
                                showDownArrowIcon={true}
                                error={
                                  formik.errors.educationHistory &&
                                  formik.errors.educationHistory[index] &&
                                  formik.errors.educationHistory[index]
                                    .instituteLocationCity
                                }
                                errorMessage={
                                  formik.errors.educationHistory &&
                                  formik.errors.educationHistory[index] &&
                                  formik.errors.educationHistory[index]
                                    .instituteLocationCity
                                }
                              />
                              <ThumbsUpDown
                                Thumbsup={Thumbsup}
                                Thumbsdown={Thumbsdown}
                                onThumbsUpClick={(state, type) =>
                                  handleThumbsUpClick(state, type, true)
                                }
                                onThumbsDownClick={(state, type) =>
                                  handleThumbsUpClick(state, type, false)
                                }
                                thumbsUpType={`educationHistory[${index}].instituteLocationCity`}
                              />
                            </div>
                            <SideHeadings
                              className="side-headings"
                              label="Institute Country Location"
                            />
                            <div className="data thumbsup compare-information">
                              <CustomAutocomplete
                                key={`education-${index}-country`}
                                InputWrapperStyle={{
                                  padding: "2px 7px",
                                  width: "30vw",
                                }}
                                listBoxStyle={{
                                  width: "30vw",
                                }}
                                freeSolo={true}
                                text={true}
                                defaultValue={
                                  education?.instituteLocationCountry
                                }
                                placeholder="Country"
                                options={city}
                                onChange={(newValue) => {
                                  formik.setFieldValue(
                                    `educationHistory[${index}].instituteLocationCountry`,
                                    newValue
                                  );
                                }}
                                showDownArrowIcon={true}
                                error={
                                  formik.errors.educationHistory &&
                                  formik.errors.educationHistory[index] &&
                                  formik.errors.educationHistory[index]
                                    .instituteLocationCountry
                                }
                                errorMessage={
                                  formik.errors.educationHistory &&
                                  formik.errors.educationHistory[index] &&
                                  formik.errors.educationHistory[index]
                                    .instituteLocationCountry
                                }
                              />
                              <ThumbsUpDown
                                Thumbsup={Thumbsup}
                                Thumbsdown={Thumbsdown}
                                onThumbsUpClick={(state, type) =>
                                  handleThumbsUpClick(state, type, true)
                                }
                                onThumbsDownClick={(state, type) =>
                                  handleThumbsUpClick(state, type, false)
                                }
                                thumbsUpType={`educationHistory[${index}].instituteLocationCountry`}
                              />
                            </div>
                            <SideHeadings
                              className="side-headings"
                              label="Degree"
                            />
                            <div className="data thumbsup compare-information">
                              <TextField
                                containerClassName="education-textfield"
                                className="compare-textfield"
                                name={`educationHistory[${index}].degree`}
                                value={education.degree}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                }}
                                errorClassName="error-message"
                                icon={""}
                                error={
                                  formik.errors.educationHistory &&
                                  formik.errors.educationHistory[index] &&
                                  formik.errors.educationHistory[index].degree
                                }
                                errorMessage={
                                  formik.errors.educationHistory &&
                                  formik.errors.educationHistory[index] &&
                                  formik.errors.educationHistory[index].degree
                                }
                              />
                              <ThumbsUpDown
                                Thumbsup={Thumbsup}
                                Thumbsdown={Thumbsdown}
                                onThumbsUpClick={(state, type) =>
                                  handleThumbsUpClick(state, type, true)
                                }
                                onThumbsDownClick={(state, type) =>
                                  handleThumbsUpClick(state, type, false)
                                }
                                thumbsUpType={`educationHistory[${index}].degree`}
                              />
                            </div>
                            <SideHeadings
                              className="side-headings"
                              label="Field Of Study"
                            />
                            <div className="data thumbsup compare-information">
                              <TextField
                                containerClassName="education-textfield"
                                className="compare-textfield"
                                name={`educationHistory[${index}].fieldOfStudy`}
                                value={education.fieldOfStudy}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                }}
                                errorClassName="error-message"
                                icon={""}
                                error={
                                  formik.errors.educationHistory &&
                                  formik.errors.educationHistory[index] &&
                                  formik.errors.educationHistory[index]
                                    .fieldOfStudy
                                }
                                errorMessage={
                                  formik.errors.educationHistory &&
                                  formik.errors.educationHistory[index] &&
                                  formik.errors.educationHistory[index]
                                    .fieldOfStudy
                                }
                              />
                              <ThumbsUpDown
                                Thumbsup={Thumbsup}
                                Thumbsdown={Thumbsdown}
                                onThumbsUpClick={(state, type) =>
                                  handleThumbsUpClick(state, type, true)
                                }
                                onThumbsDownClick={(state, type) =>
                                  handleThumbsUpClick(state, type, false)
                                }
                                thumbsUpType={`educationHistory[${index}].fieldOfStudy`}
                              />
                            </div>
                            <SideHeadings
                              className="side-headings"
                              label="Graduation Date"
                            />
                            <div className="data thumbsup CustomDatePicker compare-information">
                              <CustomDatePicker
                                type="month"
                                form={formik}
                                field={{
                                  value: education?.graduationDate,
                                  name: `educationHistory[${index}].graduationDate`,
                                  onChange: formik.handleChange,
                                  onBlur: formik.handleBlur,
                                }}
                                errorMessage={
                                  formik.errors.educationHistory &&
                                  formik.errors.educationHistory[index] &&
                                  formik.errors.educationHistory[index]
                                    .graduationDate
                                }
                              />
                              <ThumbsUpDown
                                Thumbsup={Thumbsup}
                                Thumbsdown={Thumbsdown}
                                onThumbsUpClick={(state, type) =>
                                  handleThumbsUpClick(state, type, true)
                                }
                                onThumbsDownClick={(state, type) =>
                                  handleThumbsUpClick(state, type, false)
                                }
                                thumbsUpType={`educationHistory[${index}].graduationDate`}
                              />
                            </div>
                            <SideHeadings
                              className="side-headings"
                              label="GPA Or TestScore"
                            />
                            <div className="data thumbsup compare-information">
                              <TextField
                                containerClassName="education-textfield"
                                className="compare-textfield"
                                name={`educationHistory[${index}].GPAOrTestScore`}
                                value={education.GPAOrTestScore}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                }}
                                errorClassName="error-message"
                                icon={""}
                                error={
                                  formik.errors.educationHistory &&
                                  formik.errors.educationHistory[index] &&
                                  formik.errors.educationHistory[index]
                                    .GPAOrTestScore
                                }
                                errorMessage={
                                  formik.errors.educationHistory &&
                                  formik.errors.educationHistory[index] &&
                                  formik.errors.educationHistory[index]
                                    .GPAOrTestScore
                                }
                              />
                              <ThumbsUpDown
                                Thumbsup={Thumbsup}
                                Thumbsdown={Thumbsdown}
                                onThumbsUpClick={(state, type) =>
                                  handleThumbsUpClick(state, type, true)
                                }
                                onThumbsDownClick={(state, type) =>
                                  handleThumbsUpClick(state, type, false)
                                }
                                thumbsUpType={`educationHistory[${index}].GPAOrTestScore`}
                              />
                            </div>
                            <DynamicCheckbox
                              label="Currently Studying"
                              id={`currentlyStudying-${index}`}
                              containerStyle={{
                                paddingTop: "12px",
                                fontSize: "14px",
                                fontWeight: "400",
                                marginTop: "-29px",
                                marginLeft: "-9px",
                              }}
                              checked={education?.currentlyStudying}
                              onChange={() =>
                                handleCheckboxChange(
                                  "educationHistory",
                                  index,
                                  "currentlyStudying"
                                )
                              }
                            />
                            <div className="button-group">
                              {index ===
                                formik.values.educationHistory.length - 1 && (
                                <Button
                                  event={() => addContainer("educationHistory")}
                                  label="Add Education"
                                  className="add-btn"
                                ></Button>
                              )}
                              {formik.values.educationHistory.length > 1 && (
                                <Button
                                  event={() =>
                                    removeContainer("educationHistory", index)
                                  }
                                  label="Remove Education"
                                  className="delete-btn"
                                ></Button>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    {formik.values.certificationsAndLicenses &&
                      formik.values.certificationsAndLicenses.map(
                        (certificate, index) => (
                          <div
                            className="data data-skills"
                            key={`certficate-${index}`}
                          >
                            <MainSideHeadings
                              label={"Certification & Licenses :"}
                            />
                            <div className="content">
                              <SideHeadings
                                className="side-headings"
                                label="Certification Name"
                              />
                              <div className="data thumbsup compare-information">
                                <TextField
                                  containerClassName="education-textfield"
                                  className="compare-textfield"
                                  name={`certificationsAndLicenses[${index}].certificationName`}
                                  value={certificate.certificationName}
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                  }}
                                  errorClassName="error-message"
                                  icon={""}
                                  error={
                                    formik.errors.certificationsAndLicenses &&
                                    formik.errors.certificationsAndLicenses[
                                      index
                                    ] &&
                                    formik.errors.certificationsAndLicenses[
                                      index
                                    ].certificationName
                                  }
                                  errorMessage={
                                    formik.errors.certificationsAndLicenses &&
                                    formik.errors.certificationsAndLicenses[
                                      index
                                    ] &&
                                    formik.errors.certificationsAndLicenses[
                                      index
                                    ].certificationName
                                  }
                                />
                                <ThumbsUpDown
                                  Thumbsup={Thumbsup}
                                  Thumbsdown={Thumbsdown}
                                  onThumbsUpClick={(state, type) =>
                                    handleThumbsUpClick(state, type, true)
                                  }
                                  onThumbsDownClick={(state, type) =>
                                    handleThumbsUpClick(state, type, false)
                                  }
                                  thumbsUpType={`certificationsAndLicenses[${index}].certificationName`}
                                />
                              </div>
                              <SideHeadings
                                className="side-headings"
                                label="Issuing Organization"
                              />
                              <div className="data thumbsup compare-information">
                                <TextField
                                  containerClassName="education-textfield"
                                  className="compare-textfield"
                                  name={`certificationsAndLicenses[${index}].issuingOrganization`}
                                  value={certificate.issuingOrganization}
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                  }}
                                  errorClassName="error-message"
                                  icon={""}
                                  error={
                                    formik.errors.certificationsAndLicenses &&
                                    formik.errors.certificationsAndLicenses[
                                      index
                                    ] &&
                                    formik.errors.certificationsAndLicenses[
                                      index
                                    ].issuingOrganization
                                  }
                                  errorMessage={
                                    formik.errors.certificationsAndLicenses &&
                                    formik.errors.certificationsAndLicenses[
                                      index
                                    ] &&
                                    formik.errors.certificationsAndLicenses[
                                      index
                                    ].issuingOrganization
                                  }
                                />
                                <ThumbsUpDown
                                  Thumbsup={Thumbsup}
                                  Thumbsdown={Thumbsdown}
                                  onThumbsUpClick={(state, type) =>
                                    handleThumbsUpClick(state, type, true)
                                  }
                                  onThumbsDownClick={(state, type) =>
                                    handleThumbsUpClick(state, type, false)
                                  }
                                  thumbsUpType={`certificationsAndLicenses[${index}].issuingOrganization`}
                                />
                              </div>
                              <SideHeadings
                                className="side-headings"
                                label="Issue Date "
                              />
                              <div className="data thumbsup CustomDatePicker compare-information">
                                <CustomDatePicker
                                  type="month"
                                  form={formik}
                                  field={{
                                    value: certificate?.issueDate,
                                    name: `certificationsAndLicenses[${index}].issueDate`,
                                    onChange: formik.handleChange,
                                    onBlur: formik.handleBlur,
                                  }}
                                  errorMessage={
                                    formik.errors.certificationsAndLicenses &&
                                    formik.errors.certificationsAndLicenses[
                                      index
                                    ] &&
                                    formik.errors.certificationsAndLicenses[
                                      index
                                    ].issueDate
                                  }
                                  placeholder="Ceftificate Issue Date"
                                />
                                <ThumbsUpDown
                                  Thumbsup={Thumbsup}
                                  Thumbsdown={Thumbsdown}
                                  onThumbsUpClick={(state, type) =>
                                    handleThumbsUpClick(state, type, true)
                                  }
                                  onThumbsDownClick={(state, type) =>
                                    handleThumbsUpClick(state, type, false)
                                  }
                                  thumbsUpType={`certificationsAndLicenses[${index}].issueDate`}
                                />
                              </div>
                              <div className="button-group">
                                {index ===
                                  formik.values.certificationsAndLicenses
                                    .length -
                                    1 && (
                                  <Button
                                    event={() =>
                                      addContainer("certificationsAndLicenses")
                                    }
                                    label="Add Certificate"
                                    className="add-btn"
                                  ></Button>
                                )}
                                {formik.values.certificationsAndLicenses
                                  .length > 1 && (
                                  <Button
                                    event={() =>
                                      removeContainer(
                                        "certificationsAndLicenses",
                                        index
                                      )
                                    }
                                    label="Remove Certificate"
                                    className="delete-btn"
                                  ></Button>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    {formik.values.careerHistory &&
                      formik.values.careerHistory.map((career, index) => (
                        <div
                          className="data data-skills"
                          key={`career-${index}`}
                        >
                          <MainSideHeadings label={"Experience :"} />
                          <div className="content">
                            <SideHeadings
                              className="side-headings"
                              label="Job Title"
                            />
                            <div className="data thumbsup compare-information">
                              <TextField
                                containerClassName="education-textfield"
                                className="compare-textfield"
                                name={`careerHistory[${index}].jobTitle`}
                                value={career.jobTitle}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                }}
                                errorClassName="error-message"
                                icon={""}
                                error={
                                  formik.errors.careerHistory &&
                                  formik.errors.careerHistory[index] &&
                                  formik.errors.careerHistory[index].jobTitle
                                }
                                errorMessage={
                                  formik.errors.careerHistory &&
                                  formik.errors.careerHistory[index] &&
                                  formik.errors.careerHistory[index].jobTitle
                                }
                              />
                              <ThumbsUpDown
                                Thumbsup={Thumbsup}
                                Thumbsdown={Thumbsdown}
                                onThumbsUpClick={(state, type) =>
                                  handleThumbsUpClick(state, type, true)
                                }
                                onThumbsDownClick={(state, type) =>
                                  handleThumbsUpClick(state, type, false)
                                }
                                thumbsUpType={`careerHistory[${index}].jobTitle`}
                              />
                            </div>
                            <SideHeadings
                              className="side-headings"
                              label="Company Name"
                            />
                            <div className="data thumbsup compare-information">
                              <TextField
                                containerClassName="education-textfield"
                                className="compare-textfield"
                                name={`careerHistory[${index}].jobCompany`}
                                value={career.jobCompany}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                }}
                                errorClassName="error-message"
                                icon={""}
                                error={
                                  formik.errors.careerHistory &&
                                  formik.errors.careerHistory[index] &&
                                  formik.errors.careerHistory[index].jobCompany
                                }
                                errorMessage={
                                  formik.errors.careerHistory &&
                                  formik.errors.careerHistory[index] &&
                                  formik.errors.careerHistory[index].jobCompany
                                }
                              />
                              <ThumbsUpDown
                                Thumbsup={Thumbsup}
                                Thumbsdown={Thumbsdown}
                                onThumbsUpClick={(state, type) =>
                                  handleThumbsUpClick(state, type, true)
                                }
                                onThumbsDownClick={(state, type) =>
                                  handleThumbsUpClick(state, type, false)
                                }
                                thumbsUpType={`careerHistory[${index}].jobCompany`}
                              />
                            </div>
                            <SideHeadings
                              className="side-headings"
                              label="Company City"
                            />
                            <div className="data thumbsup compare-information">
                              <CustomAutocomplete
                                key={`career-${index}-city`}
                                InputWrapperStyle={{
                                  padding: "2px 7px",
                                  width: "30vw",
                                }}
                                listBoxStyle={{
                                  width: "30vw",
                                }}
                                freeSolo={true}
                                text={true}
                                defaultValue={career?.jobLocationCity}
                                placeholder="City"
                                options={city}
                                onChange={(newValue) => {
                                  formik.setFieldValue(
                                    `careerHistory[${index}].jobLocationCity`,
                                    newValue
                                  );
                                }}
                                showDownArrowIcon={true}
                                error={
                                  formik.errors.careerHistory &&
                                  formik.errors.careerHistory[index] &&
                                  formik.errors.careerHistory[index]
                                    .jobLocationCity
                                }
                                errorMessage={
                                  formik.errors.careerHistory &&
                                  formik.errors.careerHistory[index] &&
                                  formik.errors.careerHistory[index]
                                    .jobLocationCity
                                }
                              />
                              <ThumbsUpDown
                                Thumbsup={Thumbsup}
                                Thumbsdown={Thumbsdown}
                                onThumbsUpClick={(state, type) =>
                                  handleThumbsUpClick(state, type, true)
                                }
                                onThumbsDownClick={(state, type) =>
                                  handleThumbsUpClick(state, type, false)
                                }
                                thumbsUpType={`careerHistory[${index}].jobLocationCity`}
                              />
                            </div>
                            <SideHeadings
                              className="side-headings"
                              label="Company Country"
                            />
                            <div className="data thumbsup compare-information">
                              <CustomAutocomplete
                                key={`career-${index}-country`}
                                InputWrapperStyle={{
                                  padding: "2px 7px",
                                  width: "30vw",
                                }}
                                listBoxStyle={{
                                  width: "30vw",
                                }}
                                freeSolo={true}
                                text={true}
                                defaultValue={career?.jobLocationCountry}
                                placeholder="Country"
                                options={country}
                                onChange={(newValue) => {
                                  formik.setFieldValue(
                                    `careerHistory[${index}].jobLocationCountry`,
                                    newValue
                                  );
                                }}
                                showDownArrowIcon={true}
                                error={
                                  formik.errors.careerHistory &&
                                  formik.errors.careerHistory[index] &&
                                  formik.errors.careerHistory[index]
                                    .jobLocationCountry
                                }
                                errorMessage={
                                  formik.errors.careerHistory &&
                                  formik.errors.careerHistory[index] &&
                                  formik.errors.careerHistory[index]
                                    .jobLocationCountry
                                }
                              />
                              <ThumbsUpDown
                                Thumbsup={Thumbsup}
                                Thumbsdown={Thumbsdown}
                                onThumbsUpClick={(state, type) =>
                                  handleThumbsUpClick(state, type, true)
                                }
                                onThumbsDownClick={(state, type) =>
                                  handleThumbsUpClick(state, type, false)
                                }
                                thumbsUpType={`careerHistory[${index}].jobLocationCountry`}
                              />
                            </div>
                            <SideHeadings
                              className="side-headings"
                              label="Work Experience"
                            />
                            <div className="data thumbsup CustomDatePicker compare-information">
                              <div className="workDuration-container">
                                <CustomDatePicker
                                  type="month"
                                  placeholder="Start Date"
                                  form={formik}
                                  field={{
                                    value: career?.startDate,
                                    name: `careerHistory[${index}].startDate`,
                                    onChange: formik.handleChange,
                                    onBlur: formik.handleBlur,
                                  }}
                                  errorMessage={
                                    formik.errors.careerHistory &&
                                    formik.errors.careerHistory[index] &&
                                    formik.errors.careerHistory[index].startDate
                                  }
                                />
                                <span>To</span>
                                <CustomDatePicker
                                  type="month"
                                  placeholder="End Date"
                                  form={formik}
                                  field={{
                                    value: career?.endDate,
                                    name: `careerHistory[${index}].endDate`,
                                    onChange: formik.handleChange,
                                    onBlur: formik.handleBlur,
                                  }}
                                  errorMessage={
                                    formik.errors.careerHistory &&
                                    formik.errors.careerHistory[index] &&
                                    formik.errors.careerHistory[index].endDate
                                  }
                                  startDate={career?.startDate}
                                />
                              </div>
                              <ThumbsUpDown
                                Thumbsup={Thumbsup}
                                Thumbsdown={Thumbsdown}
                                onThumbsUpClick={(state, type) =>
                                  handleThumbsUpClick(state, type, true)
                                }
                                onThumbsDownClick={(state, type) =>
                                  handleThumbsUpClick(state, type, false)
                                }
                                thumbsUpType={"Work Experience"}
                              />
                            </div>
                            <DynamicCheckbox
                              label="Currently Working Here"
                              id={`careerHistory-${index}`}
                              containerStyle={{
                                paddingTop: "12px",
                                fontSize: "14px",
                                fontWeight: "400",
                                marginTop: "-29px",
                                marginLeft: "-9px",
                              }}
                              checked={career.currentlyWorking}
                              onChange={() =>
                                handleCheckboxChange(
                                  "careerHistory",
                                  index,
                                  "currentlyWorking"
                                )
                              }
                            />
                            <div className="button-group">
                              {index ===
                                formik.values.careerHistory.length - 1 && (
                                <Button
                                  event={() => addContainer("careerHistory")}
                                  label="Add Experience"
                                  className="add-btn"
                                ></Button>
                              )}
                              {formik.values.careerHistory.length > 1 && (
                                <Button
                                  event={() =>
                                    removeContainer("careerHistory", index)
                                  }
                                  label="Remove Experience"
                                  className="delete-btn"
                                ></Button>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    {formik.values.candidatesProjects &&
                      formik.values.candidatesProjects.map((project, index) => (
                        <div
                          className="data data-skills"
                          key={`project-${index}`}
                        >
                          <MainSideHeadings label={"Projects :"} />
                          <div className="content">
                            <SideHeadings
                              className="side-headings"
                              label="Project Name"
                            />
                            <div className="data thumbsup compare-information">
                              <TextField
                                containerClassName="education-textfield"
                                className="compare-textfield"
                                name={`candidatesProjects[${index}].projectName`}
                                value={project.projectName}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                }}
                                errorClassName="error-message"
                                icon={""}
                                error={
                                  formik.errors.candidatesProjects &&
                                  formik.errors.candidatesProjects[index] &&
                                  formik.errors.candidatesProjects[index]
                                    .projectName
                                }
                                errorMessage={
                                  formik.errors.candidatesProjects &&
                                  formik.errors.candidatesProjects[index] &&
                                  formik.errors.candidatesProjects[index]
                                    .projectName
                                }
                              />
                              <ThumbsUpDown
                                Thumbsup={Thumbsup}
                                Thumbsdown={Thumbsdown}
                                onThumbsUpClick={(state, type) =>
                                  handleThumbsUpClick(state, type, true)
                                }
                                onThumbsDownClick={(state, type) =>
                                  handleThumbsUpClick(state, type, false)
                                }
                                thumbsUpType={`candidatesProjects[${index}].projectName`}
                              />
                            </div>
                            <SideHeadings
                              className="side-headings"
                              label="Project Role"
                            />
                            <div className="data thumbsup compare-information">
                              <TextField
                                containerClassName="education-textfield"
                                className="compare-textfield"
                                name={`candidatesProjects[${index}].projectRole`}
                                value={project.projectRole}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                }}
                                errorClassName="error-message"
                                icon={""}
                                error={
                                  formik.errors.candidatesProjects &&
                                  formik.errors.candidatesProjects[index] &&
                                  formik.errors.candidatesProjects[index]
                                    .projectRole
                                }
                                errorMessage={
                                  formik.errors.candidatesProjects &&
                                  formik.errors.candidatesProjects[index] &&
                                  formik.errors.candidatesProjects[index]
                                    .projectRole
                                }
                              />
                              <ThumbsUpDown
                                Thumbsup={Thumbsup}
                                Thumbsdown={Thumbsdown}
                                onThumbsUpClick={(state, type) =>
                                  handleThumbsUpClick(state, type, true)
                                }
                                onThumbsDownClick={(state, type) =>
                                  handleThumbsUpClick(state, type, false)
                                }
                                thumbsUpType={`candidatesProjects[${index}].projectRole`}
                              />
                            </div>
                            <SideHeadings
                              className="side-headings"
                              label="Project Description"
                            />
                            <div className="data thumbsup compare-information">
                              <TextField
                                containerClassName="education-textfield"
                                className="compare-textfield"
                                name={`candidatesProjects[${index}].projectDescription`}
                                value={project.projectDescription}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                }}
                                errorClassName="error-message"
                                icon={""}
                                error={
                                  formik.errors.candidatesProjects &&
                                  formik.errors.candidatesProjects[index] &&
                                  formik.errors.candidatesProjects[index]
                                    .projectDescription
                                }
                                errorMessage={
                                  formik.errors.candidatesProjects &&
                                  formik.errors.candidatesProjects[index] &&
                                  formik.errors.candidatesProjects[index]
                                    .projectDescription
                                }
                              />
                              <ThumbsUpDown
                                Thumbsup={Thumbsup}
                                Thumbsdown={Thumbsdown}
                                onThumbsUpClick={(state, type) =>
                                  handleThumbsUpClick(state, type, true)
                                }
                                onThumbsDownClick={(state, type) =>
                                  handleThumbsUpClick(state, type, false)
                                }
                                thumbsUpType={`candidatesProjects[${index}].projectDescription`}
                              />
                            </div>
                            <SideHeadings
                              className="side-headings"
                              label="Project Duration"
                            />
                            <div className="data thumbsup CustomDatePicker compare-information">
                              <div className="workDuration-container">
                                <CustomDatePicker
                                  type="month"
                                  placeholder="Start Date"
                                  form={formik}
                                  field={{
                                    value: project?.startDate,
                                    name: `candidatesProjects[${index}].startDate`,
                                    onChange: formik.handleChange,
                                    onBlur: formik.handleBlur,
                                  }}
                                  errorMessage={
                                    formik.errors.candidatesProjects &&
                                    formik.errors.candidatesProjects[index] &&
                                    formik.errors.candidatesProjects[index]
                                      .startDate
                                  }
                                />
                                <span>To</span>
                                <CustomDatePicker
                                  type="month"
                                  placeholder="End Date"
                                  form={formik}
                                  field={{
                                    value: project?.endDate,
                                    name: `candidatesProjects[${index}].endDate`,
                                    onChange: formik.handleChange,
                                    onBlur: formik.handleBlur,
                                  }}
                                  errorMessage={
                                    formik.errors.candidatesProjects &&
                                    formik.errors.candidatesProjects[index] &&
                                    formik.errors.candidatesProjects[index]
                                      .endDate
                                  }
                                  startDate={project?.startDate}
                                />
                              </div>
                              <ThumbsUpDown
                                Thumbsup={Thumbsup}
                                Thumbsdown={Thumbsdown}
                                onThumbsUpClick={(state, type) =>
                                  handleThumbsUpClick(state, type, true)
                                }
                                onThumbsDownClick={(state, type) =>
                                  handleThumbsUpClick(state, type, false)
                                }
                                thumbsUpType={"Project Duration"}
                              />
                            </div>
                            <div className="button-group">
                              {index ===
                                formik.values.candidatesProjects.length - 1 && (
                                <Button
                                  event={() =>
                                    addContainer("candidatesProjects")
                                  }
                                  label="Add Project"
                                  className="add-btn"
                                ></Button>
                              )}
                              {formik.values.candidatesProjects.length > 1 && (
                                <Button
                                  event={() =>
                                    removeContainer("candidatesProjects", index)
                                  }
                                  label="Remove Project"
                                  className="delete-btn"
                                ></Button>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    <div className="recruiternotes-container">
                      <SideHeadings
                        // label={ComparePageKeys.RecruiterNotes}
                        label="Profile Summary :"
                        className="side-headings"
                      />

                      <TextArea
                        name="profileSummary"
                        className="recruiternotes"
                        value={formik.values.profileSummary}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        thumbsup={false}
                      />
                    </div>
                    <div className="update-div">
                      {" "}
                      <Button
                        className={`update ${
                          dateError ? "disabled-button" : ""
                        }`}
                        event={() => {
                          if (Object.keys(formik.errors).length > 0) {
                            scrollToErrorField();
                          }
                          formik.handleSubmit();
                        }}
                        label={ComparePageKeys.Update}
                        disabled={dateError}
                      />
                    </div>
                    {dateError && (
                      <div className="date-error-message">
                        Please fix the date error before updating.
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="resume-compare"
                style={
                  file_type === "docx"
                    ? { overflow: "hidden" }
                    : { overflow: "auto" }
                }
              >
                <div className="Extracted-div">
                  <Tooltip title="Go Back" arrow>
                    <ArrowBackIcon onClick={handleclosePopup}></ArrowBackIcon>
                  </Tooltip>

                  <h2>Resume</h2>
                </div>

                <div className="pdf-container">
                  {/* {!pdfDocument && <Loader />} */}
                  <canvas ref={canvasRef} />
                  {Boolean(pdfDocument && pdfDocument.numPages) && (
                    <div className="pdf-button-container">
                      <Button
                        disabled={page === 1 && true}
                        event={() => setPage(page - 1)}
                        label="Previous"
                      />
                      <p>
                        Page {page} of {pdfDocument.numPages}
                      </p>
                      <Button
                        disabled={page === pdfDocument.numPages && true}
                        event={() => setPage(page + 1)}
                        label="Next"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default ComparePopup;
