import React from "react";
import "./Loader.css"
function Loader() {
  return (
    <section className="dots-container">
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
  </section>
  );
}

export default Loader;
