import React from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import CompletionGif from "../Completion-Page/completed animation.json";
import "../Completion-Page/completion.css";
import { useNavigate } from "react-router-dom";
import Button from "../Typography/Button";
function Completion(props) {
  const { UploadedResumes, setUploadedResumes } = props || {};

  const navigate = useNavigate();
  const handleBack = (e) => {
    e.preventDefault();
    navigate("/file-upload");
    setUploadedResumes([]);
  };
  return (
    <div className="Completion-main-div">
      <Player
        autoplay
        loop
        src={CompletionGif}
        style={{ height: "100px", width: "100px" }}
      >
        <Controls
          visible={false}
          buttons={["play", "repeat", "frame", "debug"]}
        />
      </Player>
      <div className="Completion-main-div-content">
        <h1>
          Thanks, I Get all Your {UploadedResumes.length} Updated Resumes{" "}
        </h1>
        <p>If You Want To Upload More Resumes,</p>
        <p>Please Click Upload Page Button</p>
        <Button className="Upload-btn" event={handleBack} label="Upload Page" />
      </div>
    </div>
  );
}

export default Completion;
