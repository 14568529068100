import { useState } from "react";

export default function DynamicProps() {
  const [email, setemail] = useState("");
  const [UploadedResumes, setUploadedResumes] = useState([]);
  const [Logout, setLogout] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [LoginFormChange, setLoginFormChange] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [base64, setBase64] = useState([]);
  const [tokenExpiry, setTokenExpiry] = useState(false);
  const [isRefetched, setisRefetched] = useState(false);
  const [isViewparsedata, setisViewparsedata] = useState(false);
  const [uploadedFileNames, setUploadedFileNames] = useState([]);
  const [uploadFileID, setUploadFileID] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [resetPasswordPage,setResetPasswordPage] = useState(false)
  return {
    email,
    setemail,
    UploadedResumes,
    setUploadedResumes,
    Logout,
    setLogout,
    changesMade,
    setChangesMade,
    LoginFormChange,
    setLoginFormChange,
    responseData,
    setResponseData,
    loading,
    setLoading,
    base64,
    setBase64,
    tokenExpiry,
    setTokenExpiry,
    isRefetched,
    setisRefetched,
    isViewparsedata,
    setisViewparsedata,
    uploadedFileNames,
    setUploadedFileNames,
    uploadFileID,
    setUploadFileID,
    deleteDialog,
    setDeleteDialog,
    forgotPassword,
    setForgotPassword,
    resetPasswordPage,
    setResetPasswordPage
  };
}
