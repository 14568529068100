import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import styled from "@emotion/styled";
import { format, parse } from "date-fns";

export const StyledDatePicker = styled(DatePicker)({
  padding: "10px 10px 10px 10px",
  border: "1px solid #ccc",
  borderRadius: "7px",
  boxSizing: "border-box",
  width: "100%",
});

const CustomDatePicker = ({
  field,
  form,
  placeholder = "",
  errorMessage,
  readOnly,
  type = "month",
  disabled = false,
  startDate,
  ...props
}) => {
  const { name, value, onChange, onBlur } = field;
  const [isOpen, setIsOpen] = useState(false);

  const handleDateChange = (val) => {
    const formattedDate = val ? format(val, "MM/yyyy") : "";
    form.setFieldValue(name, formattedDate);
    form.setFieldTouched(name, true);
    onChange({
      target: {
        name,
        value: formattedDate,
      },
    });
    setIsOpen(false);
  };

  const parsedDate = value ? parse(value, "MM/yyyy", new Date()) : null;

  const parsedStartDate = startDate
    ? parse(startDate, "MM/yyyy", new Date())
    : null;

  const handleEndDateChange = (val) => {
    const formattedDate = val ? format(val, "MM/yyyy") : "";

    if (startDate && val && val < parse(startDate, "MM/yyyy", new Date())) {
      return;
    }
    form.setFieldValue(name, formattedDate);
    form.setFieldTouched(name, true);
    onChange({
      target: {
        name,
        value: formattedDate,
      },
    });
    setIsOpen(false);
  };
  const toggleCalendar = () => {
    if (!readOnly) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div style={{ position: "relative", width: "75%" }}>
      <StyledDatePicker
        {...props}
        name={name}
        selected={parsedDate}
        // onChange={handleDateChange}
        onChange={type === "month" ? handleEndDateChange : handleDateChange}
        onBlur={onBlur}
        open={!readOnly && isOpen}
        placeholderText={placeholder}
        onClickOutside={() => setIsOpen(false)}
        onCalendarOpen={!readOnly ? () => setIsOpen(true) : undefined}
        onCalendarClose={() => setIsOpen(false)}
        dateFormat="MM/yyyy"
        readOnly={readOnly}
        placeholder={placeholder}
        showMonthYearPicker={type === "month"}
        disabled={disabled}
        minDate={parsedStartDate}
        maxDate={new Date()}
      />

      <CalendarMonthIcon
        onClick={toggleCalendar}
        style={{
          position: "absolute",
          top: "6px",
          right: "10px",
          cursor: readOnly ? "not-allowed" : "pointer",
        }}
      />

      {errorMessage && (
        <div
          className="error-message"
          style={{ color: "red", fontSize: "14px" }}
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;
