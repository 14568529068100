import * as React from "react";
import PropTypes from "prop-types";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import downArrowIcon from "../../Component/Images/down-arrow.svg";
const Root = styled("div")(
  ({ theme }) => `
  color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"
  };
  font-size: 14px;
  width:90%
`
);

const InputWrapper = styled("div")(
  ({ theme }) => `
  width: 100%;
  border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#a19d9d"};
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;


  &.focused {
    border-color: #ccc;

  }

  & input {
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    color: ${
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.65)"
        : "rgba(0,0,0,.85)"
    };
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
    overflow: auto;
    ${(props) => props.readOnly && "pointer-events: none;"} 
  }

  img {
    width: 3%;
    cursor: pointer;
  }
`
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color:rgba(244, 243, 242, 1);
  border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: auto;

  &:focus {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);

const AutoCompleteOuterDiv = styled("div")(`
  width: 100%;
  border-radius: 7px;
  box-sizing: border-box;
  outline: none;
`);

const Listbox = styled("ul")(
  ({ theme }) => `
  width: 35%;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

export default function CustomAutocomplete({
  tags,
  text,
  placeholder,
  options,
  onChange = () => {},
  InputWrapperStyle = {},
  listBoxStyle = {},
  autocompleteOuterDiv = {},
  customstyle = {},
  showDownArrowIcon = false,
  error = "",
  errorMessage = "",
  readOnly = false,
  defaultValue = "",
  value: propValue,
  ...props
}) {
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    defaultValue: text ? defaultValue : [],
    multiple: tags,
    options: options,
    value: propValue,
    getOptionLabel: (option) => {
      if (typeof option === "string") {
        return option;
      }
      if (option && option.inputValue) {
        return option.inputValue;
      }
      return option && option.title ? option.title : "";
    },

    onChange: (_, newValue) => {
      if (typeof newValue === "string") {
        onChange(newValue);
      } else if (Array.isArray(newValue)) {
        const selectedTitles = newValue.map((option) => option.title || option);
        onChange(selectedTitles);
      } else if (newValue && newValue.inputValue) {
        onChange(newValue.inputValue);
      } else if (newValue) {
        onChange(newValue.title || newValue);
      } else {
        onChange("");
      }
    },

    filterOptions: (options = [], params) => {
      const filtered = options.filter((option) =>
        option.title.toLowerCase().includes(params.inputValue.toLowerCase())
      );

      if (params.inputValue !== "" && props.freeSolo) {
        const existingText = options.some(
          (option) => option.title === params.inputValue
        );
        if (!existingText) {
          filtered.push({
            inputValue: params.inputValue,
            title: `${params.inputValue}`,
          });
        }
      }

      return filtered;
    },
    selectOnFocus: true,
    clearOnBlur: true,
    handleHomeEndKeys: true,
    renderOption: (props, option) => <li {...props}>{option.title}</li>,
    freeSolo: true,
    renderInput: (params) => (
      <TextField
        {...params}
        label="Free solo with text demo"
        readOnly={readOnly}
      />
    ),
  });

  return (
    <Root>
      <AutoCompleteOuterDiv
        {...getRootProps()}
        className="outer-autocomplete"
        style={autocompleteOuterDiv}
      >
        <InputWrapper
          style={InputWrapperStyle}
          ref={setAnchorEl}
          className={focused ? "focused" : ""}
          readOnly={readOnly}
        >
          {tags &&
            value.map((option, index) => {
              let label;
              if (typeof option === "string") {
                label = option;
              } else {
                label = option.title || "";
              }

              return (
                <StyledTag
                  key={index}
                  label={label}
                  {...getTagProps({ index })}
                />
              );
            })}
          <input
            {...getInputProps()}
            placeholder={placeholder}
            readOnly={readOnly}
          />{" "}
          {showDownArrowIcon && <img src={downArrowIcon} alt="Dropdown Icon" />}
        </InputWrapper>
        {error && <div className="error-message">{errorMessage}</div>}
      </AutoCompleteOuterDiv>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()} style={listBoxStyle}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })}>
              <span>{option.title}</span>
              <CheckIcon fontSize="small" />
            </li>
          ))}
        </Listbox>
      ) : null}
    </Root>
  );
}

CustomAutocomplete.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};
