import React, { useState } from "react";
import "../CandidateFileUpload/ExtractResumeData.css";
import ClearIcon from "@mui/icons-material/Clear";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Footer from "../Footer/Footer";
import { toast } from "react-toastify";
import CompletionPage from "../Completion-Page/Completion";
import { useFormik } from "formik";
import ComparePopup from "../Compare-Popup/Compare";
import * as Yup from "yup";
import { ComparePageKeys } from "../Typography/Information";
// import { useNavigate } from "react-router-dom";
import Button from "../Typography/Button";
// import StaticValues from "../Compare-Popup/Details.json";
import { Chip } from "@mui/material";
// import outputJson from "../Compare-Popup/Output.json";
import ExpiryScreen from "../Expiry-Login-Screen/ExpiryScreen";
import Loader from "../Pre-Loader/Loader";
import Tooltip from "@mui/material/Tooltip";
import { deleteRequest } from "../../helper/Api_helper";
// import * as Url from "../../helper/url_helper";
import TextField from "../Typography/TextField";
import { DELETE_URL } from "../../helper/url_helper";
import {
  SideHeadings,
  MainSideHeadings,
  // TextArea,
  // EditedcheckBox,
} from "../Typography/SideHeadings";
import GlobalEnterKeyHandler from "../Typography/GlobalEnterKeyHandler";
import staticValues from "../Compare-Popup/Details.json";
import CustomAutocomplete from "../AutoComplete/AutoComplete";
import CustomDatePicker from "../DatePicker/DatePicker";
import DynamicCheckbox from "../CheckBox/CheckBox";
function ExtractResumeData(props) {
  const {
    UploadedResumes,
    setUploadedResumes,
    changesMade,
    setChangesMade,
    // responseData,
    setResponseData,
    loading,
    tokenExpiry,
    setTokenExpiry,
    Logout,
    // setLogout,
    // isRefetched,
    setisRefetched,
    // setisViewparsedata,
    uploadedFileNames,
    // setUploadedFileNames,
    uploadFileID,
  } = props || {};

  GlobalEnterKeyHandler();
  const [selectedResumeIndex, setSelectedResumeIndex] = useState(0);
  // const { data = {} } = responseData;
  // const { data = {} } = StaticValues;
  // const {
  //   parsed_resumes = [],
  //   file_names = [],
  //   updated_data = [],
  // } = data || {};
  // const {
  //   name = "",
  //   email = "",
  //   mobile = "",
  //   file_name = "",
  //   overall_experience = "",
  //   skills = [],
  //   // experience = [],
  //   file_url = "",
  //   file_type = "",
  //   file_uuid = "",
  //   // education = [],
  // } = parsed_resumes[selectedResumeIndex] || {};

  // const {
  //   name: updated_Name = "",
  //   email: updated_Email = "",
  //   mobile: updated_Mobile = "",
  //   overall_experience: updated_Overall_experience = "",
  //   skills: updated_Skills = [],
  //   experience: updated_Experience = [],
  //   education: updated_Education = [],
  //   // is_update = "",
  //   Recruiter_Notes = "",
  // } = updated_data[selectedResumeIndex] || {};

  //new payload object
  const { parse_data = {} } = staticValues || {};
  const { uploadfile_names = [], parsed_data = [] } = parse_data || [];
  const {
    ProfileSummary = "",
    Skills = [],
    BasicInfo = {},
    EducationHistory = [],
    CertificationsAndLicenses = [],
    CareerHistory = [],
    CandidatesProjects = [],
    // LanguageProficiencies = [],
  } = parsed_data[selectedResumeIndex] || {};
  const {
    Name = "",
    Email = "",
    PhoneNumber = "",
    CurrentLocationCity = "",
    CurrentLocationCountry = "",
    YearsOfExperience = "",
    PersonalWebsiteLink = "",
    LinkedInWebsiteLink = "",
  } = BasicInfo || {};
  const {
    Institute = "",
    InstituteLocationCity = "",
    InstituteLocationCountry = "",
    Degree = "",
    FieldOfStudy = "",
    GraduationDate = "",
    GPAOrTestScore = "",
    CurrentlyStudying = "",
  } = EducationHistory || [];
  const {
    CertificationName = "",
    IssuingOrganization = "",
    IssueDate = "",
  } = CertificationsAndLicenses || [];
  const {
    JobTitle = "",
    JobCompany = "",
    JobLocationCity = "",
    JobLocationCountry = "",
    StartDate = "",
    EndDate = "",
    CurrentlyWorking = "",
  } = CareerHistory || [];
  const {
    ProjectName = "",
    ProjectRole = "",
    ProjectDescription = "",
    StartDate: ProjectStartDate = "",
    EndDate: ProjectEndDate = "",
  } = CandidatesProjects || [];

  const updateFormikWithJsonData = () => {
    const selectedResumeData = parsed_data[selectedResumeIndex] || {};
    const {
      BasicInfo = {},
      EducationHistory = [],
      CertificationsAndLicenses = [],
      CareerHistory = [],
      CandidatesProjects = [],
      Skills = [],
      ProfileSummary = "",
    } = selectedResumeData;

    formik.setValues({
      profileSummary: ProfileSummary || "",
      skills: Skills || [],
      basicInfo: {
        name: BasicInfo.Name || "",
        email: BasicInfo.Email || "",
        phoneNumber: BasicInfo.PhoneNumber || "",
        currentLocationCity: BasicInfo.CurrentLocationCity || "",
        currentLocationCountry: BasicInfo.CurrentLocationCountry || "",
        yearsOfExperience: BasicInfo.YearsOfExperience || "",
        personalWebsiteLink: BasicInfo.PersonalWebsiteLink || "",
        linkedInWebsiteLink: BasicInfo.LinkedInWebsiteLink || "",
      },
      educationHistory: EducationHistory.map((education) => ({
        institute: education.Institute || "",
        instituteLocationCity: education.InstituteLocationCity || "",
        instituteLocationCountry: education.InstituteLocationCountry || "",
        degree: education.Degree || "",
        fieldOfStudy: education.FieldOfStudy || "",
        graduationDate: education.GraduationDate || "",
        GPAOrTestScore: education.GPAOrTestScore || "",
        currentlyStudying: education.CurrentlyStudying || false,
      })),
      certificationsAndLicenses: CertificationsAndLicenses.map(
        (certification) => ({
          certificationName: certification.CertificationName || "",
          issuingOrganization: certification.IssuingOrganization || "",
          issueDate: certification.IssueDate || "",
        })
      ),
      careerHistory: CareerHistory.map((job) => ({
        jobTitle: job.JobTitle || "",
        jobCompany: job.JobCompany || "",
        jobLocationCity: job.JobLocationCity || "",
        jobLocationCountry: job.JobLocationCountry || "",
        startDate: job.StartDate || "",
        endDate: job.EndDate || "",
        currentlyWorking: job.CurrentlyWorking || false,
      })),
      candidatesProjects: CandidatesProjects.map((project) => ({
        projectName: project.ProjectName || "",
        projectRole: project.ProjectRole || "",
        projectDescription: project.ProjectDescription || "",
        startDate: project.StartDate || "",
        endDate: project.EndDate || "",
      })),
    });
  };

  React.useEffect(() => {
    updateFormikWithJsonData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResumeIndex]);

  const handleCancel = async (index) => {
    try {
      const fileIdToDelete = uploadFileID[index];
      const response = await deleteRequest(DELETE_URL + `${fileIdToDelete}`);
      const { message = "" } = response;
      console.log("DELETE_URL", response);
      toast.success(message, { toastId: "success" });
    } catch (error) {
      console.error("File upload error:", error);
      if (error.response) {
        const { data } = error.response;
        const { detail = "", code = "", messages = [] } = data || {};
        if (messages && messages.length > 0) {
          const errorMessage = messages.map((msg) => msg.message).join(", ");
          toast.error(errorMessage, { toastId: "error" });
        }
        if (code === "token_not_valid") {
          setTokenExpiry(true);
        } else {
          toast.error(detail || code || "An error occurred", {
            toastId: "error",
          });
        }
      } else if (error.request) {
        toast.error("No response received from the server", {
          toastId: "error",
        });
      } else {
        toast.error("An error occurred", { toastId: "error" });
      }
    }
  };

  // const [selectedResume, setSelectedResume] = useState("");
  const [Thumbsup, setThumbsup] = useState(true);
  const [Thumbsdown, setThumbsDown] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [Completion, setCompletion] = useState(false);
  const [popupDataUpdated, setPopupDataUpdated] = useState(false);
  const [editedLegend, setEditedLegend] = useState([]);
  const [experienceSections, setExperienceSections] = React.useState([]);
  const [educationSections, setEducationSections] = React.useState([]);
  const [selectedSkills, setSelectedSkills] = useState({});
  const [newlyAddedSkills, setNewlyAddedSkills] = useState([]);
  const [existingSkills, setExistingSkills] = React.useState(
    (Skills[selectedResumeIndex] || []).slice()
  );
  const [newSkill, setNewSkill] = React.useState("");
  const skillItems = selectedSkills ? Object.keys(selectedSkills) : [];
  React.useEffect(() => {
    const newSkills = formik.values.skills.filter(
      (skill) => !existingSkills.includes(skill)
    );
    setNewlyAddedSkills(newSkills);
    setExistingSkills(Skills || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Skills, selectedResumeIndex, existingSkills]);
  const [dateError, setDateError] = useState(false);
  const validationSchema = Yup.object().shape({
    profileSummary: Yup.string().required("Profile summary is required"),
    skills: Yup.array().min(1, "At least one skill is required"),
    basicInfo: Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .matches(/^[A-Za-z ]+$/, "Only alphabets are allowed in the name"),
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          "Invalid email address"
        ),
      phoneNumber: Yup.string().matches(
        /^(\+\d{1,2}\s?)?(\d{10}|\d{3}[-.\s]?\d{3}[-.\s]?\d{4}|\(\d{3}\)[-\.\s]?\d{3}[-.\s]?\d{4})$/,
        "Invalid phone number"
      ),
      currentLocationCity: Yup.string()
        .required("Current location city is required")
        .matches(
          /^[A-Za-z\s]+$/,
          "Only alphabets and spaces are allowed in the city "
        ),
      currentLocationCountry: Yup.string()
        .required("Current location country is required")
        .matches(
          /^[A-Za-z\s]+$/,
          "Only alphabets and spaces are allowed in the country "
        ),
      yearsOfExperience: Yup.string()
        .required("Years of experience is required")
        .matches(
          /^[A-Za-z0-9+. ]+$/,
          "Only alphabets, numbers, space, and + sign are allowed"
        ),
      personalWebsiteLink: Yup.string()
        .matches(
          /^(ftp|http|https):\/\/[^ "]+$/,
          "Invalid URL format. Please enter a valid URL."
        )
        .url("Invalid URL"),
      linkedInWebsiteLink: Yup.string()
        .matches(
          /^(ftp|http|https):\/\/[^ "]+$/,
          "Invalid URL format. Please enter a valid URL."
        )
        .url("Invalid URL"),
    }),
    educationHistory: Yup.array().of(
      Yup.object().shape({
        institute: Yup.string()
          .required("Institute name is required")
          .matches(
            /^[A-Za-z, ]+$/,
            "Only alphabets, commas, and spaces are allowed "
          ),
        instituteLocationCity: Yup.string()
          .required("Institute location city is required")
          .matches(
            /^[A-Za-z\s]+$/,
            "Only alphabets and spaces are allowed in the city "
          ),
        instituteLocationCountry: Yup.string()
          .required("Institute location country is required")
          .matches(
            /^[A-Za-z\s]+$/,
            "Only alphabets and spaces are allowed in the country "
          ),
        degree: Yup.string()
          .required("Degree is required")
          .matches(/^[A-Za-z ]+$/, "Only alphabets and spaces are allowed "),
        fieldOfStudy: Yup.string()
          .required("Field of study is required")
          .matches(/^[A-Za-z ]+$/, "Only alphabets are allowed in the name"),
        graduationDate: Yup.string().required("Graduation date is required"),
        GPAOrTestScore: Yup.string()
          .required("GPA or test score is required")
          .matches(
            /^(?:[0-9](?:\.[0-9])?|10)$/,
            "Only floating-point numbers from 0.0 to 10.0 are allowed"
          ),
        currentlyStudying: Yup.boolean(),
      })
    ),
    certificationsAndLicenses: Yup.array().of(
      Yup.object().shape({
        certificationName: Yup.string()
          .required("Certification name is required")
          .matches(/^[A-Za-z ]+$/, "Only alphabets and spaces are allowed "),
        issuingOrganization: Yup.string()
          .required("Issuing organization is required")
          .matches(/^[A-Za-z ]+$/, "Only alphabets and spaces are allowed "),
        issueDate: Yup.string().required("Issue date is required"),
      })
    ),
    careerHistory: Yup.array().of(
      Yup.object().shape({
        jobTitle: Yup.string()
          .required("Job title is required")
          .matches(/^[A-Za-z ]+$/, "Only alphabets and spaces are allowed "),
        jobCompany: Yup.string()
          .required("Job company is required")
          .matches(/^[A-Za-z ]+$/, "Only alphabets and spaces are allowed "),
        jobLocationCity: Yup.string()
          .required("Job location city is required")
          .matches(
            /^[A-Za-z\s]+$/,
            "Only alphabets and spaces are allowed in the city "
          ),
        jobLocationCountry: Yup.string()
          .required("Job location country is required")
          .matches(
            /^[A-Za-z\s]+$/,
            "Only alphabets and spaces are allowed in the country "
          ),
        startDate: Yup.string().required("Work duration is required"),
        endDate: Yup.string().required("Work duration is required"),
        currentlyWorking: Yup.boolean(),
      })
    ),
    candidatesProjects: Yup.array().of(
      Yup.object().shape({
        projectName: Yup.string().required("Project name is required"),
        projectRole: Yup.string().required("Project role is required"),
        projectDescription: Yup.string().required(
          "Project description is required"
        ),
        startDate: Yup.string().required("Project duration is required"),
        endDate: Yup.string().required("Project duration is required"),
      })
    ),
  });
  const formik = useFormik({
    initialValues: {
      profileSummary: ProfileSummary || "",
      skills: Skills || [],
      basicInfo: {
        name: Name || "",
        email: Email || "",
        phoneNumber: PhoneNumber || "",
        currentLocationCity: CurrentLocationCity || "",
        currentLocationCountry: CurrentLocationCountry || "",
        yearsOfExperience: YearsOfExperience || "",
        personalWebsiteLink: PersonalWebsiteLink || "",
        linkedInWebsiteLink: LinkedInWebsiteLink || "",
      },
      educationHistory: [
        {
          institute: Institute || "",
          instituteLocationCity: InstituteLocationCity || "",
          instituteLocationCountry: InstituteLocationCountry || "",
          degree: Degree || "",
          fieldOfStudy: FieldOfStudy || "",
          graduationDate: GraduationDate || "",
          GPAOrTestScore: GPAOrTestScore || "",
          currentlyStudying: CurrentlyStudying || false,
        },
      ],
      certificationsAndLicenses: [
        {
          certificationName: CertificationName || "",
          issuingOrganization: IssuingOrganization || "",
          issueDate: IssueDate || "",
        },
      ],
      careerHistory: [
        {
          jobTitle: JobTitle || "",
          jobCompany: JobCompany || "",
          jobLocationCity: JobLocationCity || "",
          jobLocationCountry: JobLocationCountry || "",
          startDate: StartDate || "",
          endDate: EndDate || "",
          currentlyWorking: CurrentlyWorking || false,
        },
      ],
      candidatesProjects: [
        {
          projectName: ProjectName || "",
          projectRole: ProjectRole || "",
          projectDescription: ProjectDescription || "",
          startDate: ProjectStartDate || "",
          endDate: ProjectEndDate || "",
        },
      ],
    },
    validationSchema,
    onSubmit: async (values) => {
      toast.success("Updated Sucessfully", { toastId: "success" });
      console.log("formik.values.educationHistory", values);
      setShowPopup(false);
    },
  });

  const handleSkillChange = (event) => {
    const skill = event.target.name;
    const isChecked = event.target.checked;

    setSelectedSkills((prevSkills) => ({
      ...prevSkills,
      [skill]: isChecked,
    }));
    formik.setValues({
      ...formik.values,
      skills: Object.keys(selectedSkills).filter(
        (skill) => selectedSkills[skill]
      ),
    });
    setChangesMade(true);
  };

  const handleResumeSelection = (value, index) => {
    // setSelectedResume(value);
    setSelectedResumeIndex(index);
  };
  const handleConfirm = () => {
    if (changesMade) {
      const fieldsChanged =
        formik.values.name !== formik.initialValues.name ||
        formik.values.email !== formik.initialValues.email ||
        formik.values.mobile !== formik.initialValues.mobile ||
        formik.values.overall_experience !==
          formik.initialValues.overall_experience ||
        formik.values.experience !== formik.initialValues.experience ||
        formik.values.education !== formik.initialValues.education;

      if (fieldsChanged) {
        const selectedData = {
          name: formik.initialValues.name,
          email: formik.initialValues.email,
          mobile: formik.initialValues.mobile,
          overall_experience: formik.initialValues.overall_experience,
          experience: formik.initialValues.experience,
          education: formik.initialValues.education,
          skills: skillItems,
        };

        console.log("Extract Values:", selectedData);

        const updatedData = {
          name: formik.values.name,
          email: formik.values.email,
          mobile: formik.values.mobile,
          overall_experience: formik.values.overall_experience,
          experience: formik.values.experience,
          education: formik.values.education,
          skills: selectedSkills,
        };
        console.log("Updated Values:", updatedData);
        toast.success("Data Uploaded successfully");
        if (!popupDataUpdated) {
          setPopupDataUpdated(true);
        }
        setCompletion(true);
      }
    } else {
      toast.error("Please compare your resumes");
    }
  };

  const handleOpenPopup = () => {
    setShowPopup(true);
    setPopupDataUpdated(false);
    setChangesMade(false);
    if (
      selectedResumeIndex !== -1 &&
      !editedLegend.includes(selectedResumeIndex)
    ) {
      setEditedLegend([...editedLegend, selectedResumeIndex]);
    }
    setEducationSections([]);
    setExperienceSections([]);
  };

  const handleclosePopup = () => {
    setisRefetched(true);
    setShowPopup(false);
    setEditedLegend([]);
    formik.resetForm();
  };

  const CompletepageProps = {
    UploadedResumes,
    setUploadedResumes,
  };

  return (
    <>
      {tokenExpiry ? (
        <ExpiryScreen
          setUploadedResumes={setUploadedResumes}
          setTokenExpiry={setTokenExpiry}
          setResponseData={setResponseData}
        />
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <div
              className="main-extract"
              style={Logout ? { overflow: "hidden" } : null}
            >
              {selectedResumeIndex !== -1 && showPopup ? (
                <ComparePopup
                  formik={formik}
                  handleSkillChange={handleSkillChange}
                  selectedResumeIndex={selectedResumeIndex}
                  handleclosePopup={handleclosePopup}
                  setChangesMade={setChangesMade}
                  setNewlyAddedSkills={setNewlyAddedSkills}
                  newlyAddedSkills={newlyAddedSkills}
                  existingSkills={existingSkills}
                  setExistingSkills={setExistingSkills}
                  newSkill={newSkill}
                  setNewSkill={setNewSkill}
                  // file_url={file_url}
                  // file_type={file_type}
                  // parsed_resumes={parsed_resumes}
                  // file_uuid={file_uuid}
                  experienceSections={experienceSections}
                  setExperienceSections={setExperienceSections}
                  educationSections={educationSections}
                  setEducationSections={setEducationSections}
                  setUploadedResumes={setUploadedResumes}
                  setTokenExpiry={setTokenExpiry}
                  setResponseData={setResponseData}
                  tokenExpiry={tokenExpiry}
                  setDateError={setDateError}
                  dateError={dateError}
                  Thumbsup={Thumbsup}
                  setThumbsup={setThumbsup}
                  Thumbsdown={Thumbsdown}
                  setThumbsDown={setThumbsDown}
                  uploadFileID={uploadFileID}
                  parsed_data={parsed_data}
                />
              ) : (
                <>
                  {Completion ? (
                    <CompletionPage {...CompletepageProps} />
                  ) : (
                    <>
                      <div className="Extracted-Resume-Data">
                        <div className="Extracted-Resume-Data-Header">
                          <h3>Extracted Resume Data</h3>
                          {/* {is_update ? (
                            <div className="legend-div">
                              <p className="legend">
                                <span className="legend-span"></span>Newly
                                Updated
                              </p>
                         
                            </div>
                          ) : null} */}
                        </div>

                        <div className="Upload-Resumes">
                          <div className="resume">
                            <div className="resume-header">
                              {" "}
                              <h5>Resumes</h5>
                            </div>
                            <div className="listed-files-container">
                              {/* {parsed_resumes.map((fileName, index) => (
                                <div
                                  key={index}
                                  className={`list-files-inner ${
                                    selectedResumeIndex === index
                                      ? "active-file"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleResumeSelection(
                                      fileName.file_name,
                                      index
                                    )
                                  }
                                >
                                  <div className="list-files-icon">
                                    <UploadFileIcon />
                                    {fileName.file_name}
                                  </div>
                                  <Tooltip title="Cancel" arrow>
                                    <ClearIcon
                                    // onClick={() => handleCancel(index)}
                                    ></ClearIcon>
                                  </Tooltip>
                                </div>
                              ))} */}
                              {uploadedFileNames.map((fileName, index) => (
                                <div
                                  key={index}
                                  className={`list-files-inner ${
                                    selectedResumeIndex === index
                                      ? "active-file"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleResumeSelection(
                                      fileName.file_name,
                                      index
                                    )
                                  }
                                >
                                  <div className="list-files-icon">
                                    <UploadFileIcon />
                                    {fileName}
                                  </div>
                                  <Tooltip title="Cancel" arrow>
                                    <ClearIcon
                                      onClick={() => handleCancel(index)}
                                    ></ClearIcon>
                                  </Tooltip>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="person-details">
                            <div className="person-data">
                              <div className="person-data-header">
                                <h5>
                                  {uploadedFileNames[selectedResumeIndex]}
                                </h5>

                                <Button
                                  className="compare"
                                  type="submit"
                                  event={handleOpenPopup}
                                  label={ComparePageKeys.Compare}
                                />
                              </div>
                              <div className="data-extract  extract-data">
                                <MainSideHeadings
                                  label={ComparePageKeys.Name}
                                />

                                <p
                                  className={
                                    formik.values.basicInfo.name !== Name
                                      ? "green-text"
                                      : ""
                                  }
                                >
                                  {formik.values.basicInfo.name}
                                </p>
                              </div>
                              <div className="data-extract extract-data">
                                <MainSideHeadings
                                  label={ComparePageKeys.Email}
                                />
                                <p
                                  className={
                                    formik.values.basicInfo.email !== Email
                                      ? "green-text"
                                      : ""
                                  }
                                >
                                  {formik.values.basicInfo.email}
                                </p>
                              </div>
                              <div className="data-extract extract-data">
                                <MainSideHeadings
                                  label={ComparePageKeys.Contact}
                                />

                                <p
                                  className={
                                    formik.values.basicInfo.phoneNumber !==
                                    PhoneNumber
                                      ? "green-text"
                                      : ""
                                  }
                                >
                                  {formik.values.basicInfo.phoneNumber}
                                </p>
                              </div>
                              <div className="data-extract  extract-data">
                                <MainSideHeadings label={"City :"} />

                                <p
                                  className={
                                    formik.values.basicInfo
                                      .currentLocationCity !==
                                    CurrentLocationCity
                                      ? "green-text"
                                      : ""
                                  }
                                >
                                  {formik.values.basicInfo.currentLocationCity}
                                </p>
                              </div>
                              <div className="data-extract  extract-data">
                                <MainSideHeadings label={"Country :"} />

                                <p
                                  className={
                                    formik.values.basicInfo
                                      .currentLocationCountry !==
                                    CurrentLocationCountry
                                      ? "green-text"
                                      : ""
                                  }
                                >
                                  {
                                    formik.values.basicInfo
                                      .currentLocationCountry
                                  }
                                </p>
                              </div>
                              <div className="data-extract  extract-data">
                                <MainSideHeadings
                                  label={"Personal Website Link  :"}
                                />

                                <p
                                  className={
                                    formik.values.basicInfo
                                      .personalWebsiteLink !==
                                    PersonalWebsiteLink
                                      ? "green-text"
                                      : ""
                                  }
                                >
                                  {formik.values.basicInfo.personalWebsiteLink}
                                </p>
                              </div>
                              <div className="data-extract  extract-data">
                                <MainSideHeadings
                                  label={"LinkedIn WebsiteLink   :"}
                                />

                                <p
                                  className={
                                    formik.values.basicInfo
                                      .linkedInWebsiteLink !==
                                    LinkedInWebsiteLink
                                      ? "green-text"
                                      : ""
                                  }
                                >
                                  {formik.values.basicInfo.linkedInWebsiteLink}
                                </p>
                              </div>
                              <div className="data-extract extract-data data-skills">
                                <MainSideHeadings
                                  label={ComparePageKeys.Skills}
                                />

                                <div
                                  className={
                                    formik.values.skills.length >= 5
                                      ? "skills"
                                      : ""
                                  }
                                >
                                  {formik.values.skills.map((skill, index) => {
                                    const isNewSkill =
                                      newlyAddedSkills.includes(skill);
                                    const isOldSkill =
                                      formik.values.skills.includes(skill);

                                    return (
                                      <Chip
                                        key={index}
                                        label={skill}
                                        variant="outlined"
                                        style={{
                                          marginRight: "8px",
                                          marginBottom: "8px",
                                          backgroundColor: isNewSkill
                                            ? "green"
                                            : "#f1f7ff",
                                          color: isNewSkill
                                            ? "white"
                                            : isOldSkill
                                            ? "black"
                                            : "gray",
                                        }}
                                      />
                                    );
                                  })}
                                </div>
                              </div>
                              <div className="data-extract  extract-data data-skills extract-yearsofexperience">
                                <MainSideHeadings
                                  label={ComparePageKeys.YearsofExperience}
                                />

                                <p
                                  className={`${
                                    formik.values.basicInfo
                                      .yearsOfExperience !== YearsOfExperience
                                      ? "green-text"
                                      : ""
                                  } experience-years`}
                                >
                                  {formik.values.basicInfo.yearsOfExperience}
                                </p>
                              </div>
                              {formik.values.educationHistory?.map(
                                (education, index) => (
                                  <div
                                    className="data-extract-experience  extract-data data-skills"
                                    key={`education-${index}`}
                                  >
                                    <div className="experience-education-container">
                                      <div
                                        className="data-extract data-skills "
                                        key={`education-item-${index}`}
                                      >
                                        <MainSideHeadings
                                          label={"Education :"}
                                        />

                                        <div className="content">
                                          <div className="green">
                                            <SideHeadings
                                              className="side-headings"
                                              label="Institute"
                                            />
                                            <TextField
                                              containerClassName="education-textfield"
                                              className="compare-textfield extract-textfield"
                                              readOnly={true}
                                              name={`educationHistory[${index}].institute`}
                                              value={education.institute}
                                              icon={""}
                                              thumbsup={false}
                                            />
                                            <SideHeadings
                                              className="side-headings"
                                              label="Institute City Location"
                                            />
                                            <div className="data thumbsup compare-information">
                                              <CustomAutocomplete
                                                readOnly={true}
                                                options={[]}
                                                key={`education-${index}-city`}
                                                InputWrapperStyle={{
                                                  padding: "2px 7px",
                                                  width: "30vw",
                                                }}
                                                freeSolo={true}
                                                placeholder="City"
                                                showDownArrowIcon={true}
                                                text={true}
                                                value={
                                                  education.instituteLocationCity
                                                }
                                              />
                                            </div>

                                            <SideHeadings
                                              className="side-headings"
                                              label="Institute Country Location"
                                            />
                                            <div className="data thumbsup compare-information">
                                              <CustomAutocomplete
                                                readOnly={true}
                                                options={[]}
                                                key={`education-${index}-country`}
                                                InputWrapperStyle={{
                                                  padding: "2px 7px",
                                                  width: "30vw",
                                                }}
                                                placeholder="Country"
                                                showDownArrowIcon={true}
                                                text={true}
                                                value={
                                                  education.instituteLocationCountry
                                                }
                                              />
                                            </div>
                                            <SideHeadings
                                              className="side-headings"
                                              label="Degree"
                                            />
                                            <div className="data thumbsup compare-information">
                                              <TextField
                                                containerClassName="education-textfield"
                                                className="compare-textfield"
                                                name={`educationHistory[${index}].degree`}
                                                value={education.degree}
                                              />
                                            </div>
                                            <SideHeadings
                                              className="side-headings"
                                              label="Field Of Study"
                                            />
                                            <div className="data thumbsup compare-information">
                                              <TextField
                                                containerClassName="education-textfield"
                                                className="compare-textfield"
                                                name={`educationHistory[${index}].fieldOfStudy`}
                                                value={education.fieldOfStudy}
                                              />
                                            </div>
                                            <SideHeadings
                                              className="side-headings"
                                              label="Graduation Date"
                                            />
                                            <div className="data thumbsup compare-information">
                                              <CustomDatePicker
                                                form={formik}
                                                field={{
                                                  value:
                                                    education?.graduationDate ||
                                                    null,
                                                  name: `educationHistory[${index}].graduationDate`,
                                                }}
                                                readOnly={true}
                                                placeholder="Graduation Date"
                                              />
                                            </div>
                                            <SideHeadings
                                              className="side-headings"
                                              label="GPA Or TestScore"
                                            />
                                            <div className="data thumbsup compare-information">
                                              <TextField
                                                containerClassName="education-textfield"
                                                className="compare-textfield"
                                                name={`educationHistory[${index}].GPAOrTestScore`}
                                                value={education.GPAOrTestScore}
                                              />
                                            </div>
                                            <DynamicCheckbox
                                              label="Currently Studying"
                                              id={`currentlyStudying-${index}`}
                                              containerStyle={{
                                                paddingTop: "12px",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                marginTop: "-29px",
                                                marginLeft: "-9px",
                                              }}
                                              checked={
                                                education.currentlyStudying
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                              {formik.values.certificationsAndLicenses?.map(
                                (certificate, index) => (
                                  <div
                                    className="data-extract-experience  extract-data data-skills"
                                    key={`certificate-${index}`}
                                  >
                                    <div className="experience-education-container">
                                      <div
                                        className="data-extract data-skills "
                                        key={`certificate-item-${index}`}
                                      >
                                        {" "}
                                        <MainSideHeadings
                                          label={"Certification & Licenses :"}
                                        />{" "}
                                        <div className="content">
                                          <SideHeadings
                                            className="side-headings"
                                            label="Certification Name"
                                          />
                                          <div className="data thumbsup compare-information">
                                            <TextField
                                              containerClassName="education-textfield"
                                              className="compare-textfield"
                                              name={`certificationsAndLicenses[${index}].certificationName`}
                                              value={
                                                certificate.certificationName
                                              }
                                            />
                                          </div>
                                          <SideHeadings
                                            className="side-headings"
                                            label="Issuing Organization"
                                          />
                                          <div className="data thumbsup compare-information">
                                            <TextField
                                              containerClassName="education-textfield"
                                              className="compare-textfield"
                                              name={`certificationsAndLicenses[${index}].issuingOrganization`}
                                              value={
                                                certificate.issuingOrganization
                                              }
                                            />
                                          </div>
                                          <SideHeadings
                                            className="side-headings"
                                            label="Issue Date "
                                          />
                                          <div className="data thumbsup compare-information">
                                            <CustomDatePicker
                                              form={formik}
                                              field={{
                                                value:
                                                  certificate?.issueDate ||
                                                  null,
                                                name: `certificationsAndLicenses[${index}].issueDate`,
                                              }}
                                              readOnly={true}
                                              placeholder="Ceftificate Issue Date"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                              {formik.values.careerHistory?.map(
                                (career, index) => (
                                  <div
                                    className="data-extract-experience  extract-data data-skills"
                                    key={`careerHistory-${index}`}
                                  >
                                    <div className="experience-education-container">
                                      <div
                                        className="data-extract data-skills extract-education"
                                        key={`careerHistory-item-${index}`}
                                      >
                                        <MainSideHeadings
                                          label={"Experience :"}
                                        />

                                        <div className="content">
                                          <SideHeadings
                                            className="side-headings"
                                            label="Job Title"
                                          />
                                          <div className="data thumbsup compare-information">
                                            <TextField
                                              containerClassName="education-textfield"
                                              className="compare-textfield"
                                              name={`careerHistory[${index}].jobTitle`}
                                              value={career.jobTitle}
                                            />
                                          </div>

                                          <SideHeadings
                                            className="side-headings"
                                            label="Company Name"
                                          />
                                          <div className="data thumbsup compare-information">
                                            <TextField
                                              containerClassName="education-textfield"
                                              className="compare-textfield"
                                              name={`careerHistory[${index}].jobCompany`}
                                              value={career.jobCompany}
                                            />
                                          </div>
                                          <SideHeadings
                                            className="side-headings"
                                            label="Company City"
                                          />
                                          <div className="data thumbsup compare-information">
                                            <CustomAutocomplete
                                              readOnly={true}
                                              options={[]}
                                              key={`career-${index}-city`}
                                              InputWrapperStyle={{
                                                padding: "2px 7px",
                                                width: "30vw",
                                              }}
                                              listBoxStyle={{
                                                width: "30vw",
                                              }}
                                              text={true}
                                              value={career?.jobLocationCity}
                                              placeholder="City"
                                              showDownArrowIcon={true}
                                            />
                                          </div>
                                          <SideHeadings
                                            className="side-headings"
                                            label="Company Country"
                                          />
                                          <div className="data thumbsup compare-information">
                                            <CustomAutocomplete
                                              readOnly={true}
                                              options={[]}
                                              key={`career-${index}-country`}
                                              InputWrapperStyle={{
                                                padding: "2px 7px",
                                                width: "30vw",
                                              }}
                                              listBoxStyle={{
                                                width: "30vw",
                                              }}
                                              freeSolo={true}
                                              text={true}
                                              value={career?.jobLocationCountry}
                                              placeholder="Country"
                                              showDownArrowIcon={true}
                                            />
                                          </div>
                                          <SideHeadings
                                            className="side-headings"
                                            label="Work Experience"
                                          />
                                          <div className="data thumbsup compare-information">
                                            <div className="workDuration-container">
                                              <CustomDatePicker
                                                type="month"
                                                placeholder="Start Date"
                                                form={formik}
                                                field={{
                                                  value: career?.startDate,
                                                  name: `careerHistory[${index}].startDate`,
                                                }}
                                                readOnly={true}
                                              />
                                              <span>To</span>
                                              <CustomDatePicker
                                                type="month"
                                                placeholder="End Date"
                                                form={formik}
                                                field={{
                                                  value: career?.endDate,
                                                  name: `careerHistory[${index}].endDate`,
                                                }}
                                                readOnly={true}
                                              />
                                            </div>
                                          </div>
                                          <DynamicCheckbox
                                            label="Currently Working Here"
                                            id={`careerHistory-${index}`}
                                            containerStyle={{
                                              paddingTop: "12px",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              marginTop: "-29px",
                                              marginLeft: "-9px",
                                            }}
                                            checked={career?.currentlyWorking}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                              {formik.values.candidatesProjects?.map(
                                (project, index) => (
                                  <div
                                    className="data-extract-experience  extract-data data-skills"
                                    key={`project-${index}`}
                                  >
                                    <div className="experience-education-container">
                                      <div
                                        className="data-extract data-skills extract-education"
                                        key={`careerHistory-item-${index}`}
                                      >
                                        <MainSideHeadings
                                          label={"Projects :"}
                                        />
                                        <div className="content">
                                          <SideHeadings
                                            className="side-headings"
                                            label="Project Name"
                                          />
                                          <div className="data thumbsup compare-information">
                                            <TextField
                                              containerClassName="education-textfield"
                                              className="compare-textfield"
                                              name={`candidatesProjects[${index}].projectName`}
                                              value={project.projectName}
                                            />
                                          </div>
                                          <SideHeadings
                                            className="side-headings"
                                            label="Project Role"
                                          />
                                          <div className="data thumbsup compare-information">
                                            <TextField
                                              containerClassName="education-textfield"
                                              className="compare-textfield"
                                              name={`candidatesProjects[${index}].projectRole`}
                                              value={project.projectRole}
                                            />
                                          </div>
                                          <SideHeadings
                                            className="side-headings"
                                            label="Project Description"
                                          />
                                          <div className="data thumbsup compare-information">
                                            <TextField
                                              containerClassName="education-textfield"
                                              className="compare-textfield"
                                              name={`candidatesProjects[${index}].projectDescription`}
                                              value={project.projectDescription}
                                            />
                                          </div>
                                          <SideHeadings
                                            className="side-headings"
                                            label="Project Duration"
                                          />
                                          <div className="data thumbsup compare-information">
                                            <div className="workDuration-container">
                                              <CustomDatePicker
                                                type="month"
                                                placeholder="Start Date"
                                                form={formik}
                                                field={{
                                                  value: project?.startDate,
                                                  name: `candidatesProjects[${index}].startDate`,
                                                }}
                                                readOnly={true}
                                              />
                                              <span>To</span>
                                              <CustomDatePicker
                                                type="month"
                                                placeholder="End Date"
                                                form={formik}
                                                field={{
                                                  value: project?.endDate,
                                                  name: `candidatesProjects[${index}].endDate`,
                                                }}
                                                readOnly={true}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                              <div className="extract-recruiter-note ">
                                {formik.values.profileSummary.trim() !== "" ? (
                                  <div className="data-extract data-skills">
                                    <MainSideHeadings
                                      label={"Profile Summary :"}
                                    />
                                    <div className="content">
                                      <p className="side-heading">
                                        Profile Summary
                                      </p>
                                      <div className="green">
                                        <ul>{formik.values.profileSummary}</ul>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="Confirm-button">
                          <Button
                            className="Confirm"
                            event={handleConfirm}
                            type="submit"
                            label={ComparePageKeys.Confirm}
                            disabled={
                              selectedResumeIndex === -1 || !formik.isValid
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              <Footer />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ExtractResumeData;
