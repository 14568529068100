import React from "react";
import "../ErrorPage/ErrorPage.css";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import errorGif from "../ErrorPage/animation_lk7u3ugx.json";
import Button from "../Typography/Button";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
function Errorpage() {
  const navigate = useNavigate();
  const handleBack = (e) => {
    e.preventDefault();
    navigate("/file-upload");
  };
  return (
    <>
      <div className="main">
        <Player
          autoplay
          loop
          src={errorGif}
          style={{ height: "100px", width: "100px" }}
        >
          <Controls
            visible={false}
            buttons={["play", "repeat", "frame", "debug"]}
          />
        </Player>
        <div className="error-div">
          <h1 className="error">404 Error</h1>
          <h3 className="exist">The Page You're looking for doesn't exist</h3>
        </div>
        <Button className="Upload-btn" event={handleBack} label="Go To Home" />
      </div>
      <Footer />
    </>
  );
}

export default Errorpage;
