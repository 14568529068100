import React from "react";
import ProgressBars from "./Circular";

function Button(props) {
  const {
    event = () => "",
    type = "button",
    label = "",
    className = "",
    loading = false,
    customLoading = false,
    disabled = false,
  } = props || {};

  return (
    <button
      onClick={event}
      type={type}
      className={className}
      style={
        loading || customLoading ? { cursor: "no-drop" } : { cursor: "pointer" }
      }
      disabled={disabled}
    >
      {(loading || customLoading) && <ProgressBars />}
      {label}
    </button>
  );
}

export default Button;
