import React, { useRef, useState } from "react";
import "../CandidateFileUpload/candidatefile.css";
import UploadImg from "../Images/upload-icon.svg";
import AddResume from "./AddResume";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ExpiryScreen from "../Expiry-Login-Screen/ExpiryScreen";

function CandidateFilesUpload(props) {
  const navigate = useNavigate();
  const FileRef = useRef();
  const maxFileSize = 10 * 1024 * 1024;
  const allowedFileTypes = ["pdf", "txt", "doc", "docx", "rtf"];
  const {
    UploadedResumes,
    setUploadedResumes,
    responseData,
    setResponseData = () => "",
    loading,
    setLoading,
    base64,
    setBase64,
    tokenExpiry,
    setTokenExpiry,
    Logout,
    // setLogout,
    // setisViewparsedata,
    uploadedFileNames,
    setUploadedFileNames,
    uploadFileID,
    setUploadFileID,
  } = props || {};

  const [isDragOver, setIsDragOver] = useState(false);
  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    validateAndUploadFiles(files);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = Array.from(e.dataTransfer.files);
    validateAndUploadFiles(files);
  };
  const validateAndUploadFiles = async (files) => {
    const validFiles = [];
    const invalidFiles = [];

    if (UploadedResumes.length + files.length > 20) {
      toast.warning("You can upload a maximum of 20 files.", {
        toastId: "warning",
      });
      return;
    }

    const promises = files.map(async (file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const fileSize = file.size;

      if (fileSize > maxFileSize) {
        toast.error(
          `File "${file.name}" exceeds the maximum file size limit.`,
          { toastId: "error" }
        );
        invalidFiles.push(file);
      } else if (allowedFileTypes.includes(fileExtension)) {
        const isDuplicate = UploadedResumes.some(
          (uploadedFile) => uploadedFile.name === file.name
        );

        if (isDuplicate) {
          toast.error(`File "${file.name}" already exists.`, {
            toastId: "error",
          });
          invalidFiles.push(file);
        } else {
          validFiles.push(file);
        }
      } else {
        toast.error(
          "Some files could not be uploaded. Please check the file types.",
          { toastId: "error" }
        );
        invalidFiles.push(file);
      }
    });

    await Promise.all(promises);
    setUploadedResumes((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleResetForm = () => {
    setUploadedResumes([]);
    navigate("/file-upload");
  };

  const ResumePageprops = {
    UploadedResumes,
    setUploadedResumes,
    handleFileChange,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    setIsDragOver,
    handleResetForm,
    FileRef,
    responseData,
    fileToBase64,
    setResponseData,
    loading,
    setLoading,
    base64,
    setBase64,
    tokenExpiry,
    setTokenExpiry,
    uploadedFileNames,
    setUploadedFileNames,
    uploadFileID,
    setUploadFileID,
  };

  return (
    <>
      {tokenExpiry ? (
        <ExpiryScreen
          setUploadedResumes={setUploadedResumes}
          setTokenExpiry={setTokenExpiry}
          setResponseData={setResponseData}
        />
      ) : (
        <div
          className="main-candidate"
          style={Logout ? { overflow: "hidden" } : null}
        >
          {UploadedResumes.length > 0 ? (
            <AddResume {...ResumePageprops} />
          ) : (
            <div>
              <div className="resume-upload-container">
                <h2 className="upload-heading">Upload Resumes</h2>
                <form>
                  <div
                    className={`file-drop-container ${
                      isDragOver ? "drag-over" : ""
                    }`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    onClick={() => {
                      FileRef.current.click();
                    }}
                  >
                    <div className="upload-img">
                      <img src={UploadImg} alt="upload icon"></img>
                    </div>

                    <label
                      htmlFor="resume-upload"
                      className="file-upload-label"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <p className="drag-drop">
                        Drag & drop or{" "}
                        <span className="choose-file">choose file</span> all
                        resumes here
                      </p>
                      <input
                        hidden={true}
                        ref={FileRef}
                        id="resume-upload"
                        type="file"
                        accept=".pdf,.doc,.docx"
                        onChange={handleFileChange}
                        multiple
                      />
                    </label>
                    <div className="supported-container">
                      <p className="supported-file">
                        Supported Formats: PDF, Word (Doc), DocX,txt,rtf
                      </p>
                      <p className="supported-file">
                        You Can Upload Maximum{" "}
                        <span className="maximum">20</span> Files
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
          <Footer loading={loading} />
        </div>
      )}
    </>
  );
}

export default CandidateFilesUpload;
