import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

export default function Paginations({
  onPageChange,
  totalPages,
  currentPage,
  itemsPerPage,
  totalItems,
}) {
  const handleChange = (event, value) => {
    onPageChange(value);
  };

  const TotalFilesEntires = () => {
    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, totalItems);
    return (
      <div className="pagination-info">
        ( {startItem} - {endItem} ) of{" "}
        <span className="total-items">{totalItems}</span>
      </div>
    );
  };

  return (
    <Stack spacing={2}>
      <Pagination
        count={totalPages}
        page={currentPage > totalPages ? totalPages : currentPage}
        color="primary"
        onChange={handleChange}
      />
      {TotalFilesEntires()}
    </Stack>
  );
}
